import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import FreightBook from "./pages/freightbook";
import FreightQuote from "./pages/freightquote";
import Landing from "./pages/landing";
import Services from "./pages/services";
import WhyChooseUs from "./pages/whyChooseUs";
import UniqueOffering from "./pages/uniqueOffering";
import HowItWorks from "./pages/howItWorks";

function App() {
  return (
    <Router basename={"/quote"}>
      <Routes>
        <Route path="/" element={<Landing />} /> 
        <Route exact path="/landing" element={<FreightBook />} />
        <Route path="/book/details" element={<FreightBook />} />
        <Route path="/book/quote" element={<FreightQuote />} /> 
        <Route path="/services" element={<Services />} /> 
        <Route path="/why-choose-us" element={<WhyChooseUs />} /> 
        <Route path="/unique-offering" element={<UniqueOffering />} /> 
        <Route path="/how-it-works" element={<HowItWorks />} /> 
      </Routes>
    </Router>
  );
}

export default App;
