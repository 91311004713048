import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import Icon1 from '../images/icon1.png';
import Icon2 from '../images/icon2.png';
import Icon3 from '../images/icon3.png';
import Icon4 from '../images/icon4.png';
import FeatureIcon1 from '../images/featureIcon1.png';
import FeatureIcon2 from '../images/featureIcon2.png';
import FeatureIcon3 from '../images/featureIcon3.png';
import FeatureIcon4 from '../images/featureIcon4.png';
import FeatureIcon5 from '../images/featureIcon5.png';
import FeatureIcon6 from '../images/featureIcon6.png';
import FeatureIcon7 from '../images/featureIcon7.png';
import InfoImg from '../images/info-img.png';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from './MapComponent';
import contactgray from '../images/contactgray.png';
import contactwhite from '../images/contactwhite.png';
import dategray from '../images/dategray.png';
import datewhite from '../images/datewhite.png';
import plocationgray from '../images/plocationgray.png';
import plocationwhite from '../images/plocationwhite.png';
import dlocationgray from '../images/dlocationgray.png';
import dlocationwhite from '../images/dlocationwhite.png';
import shipmentgray from '../images/shipmentgray.png';
import shipmentwhite from '../images/shipmentwhite.png';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';



const Landing = () => {

    // initial vales  ----------------------------------------------------------------
    const initialShipment = {
        handlingUnits: 1,
        locationType: 'SKD',
        description: '',
        nmfcCode: '',
        dimensions: {
            length: '',
            width: '',
            height: '',
            weight: '',
        },
        stackable: 'Not Stackable',
        temperatureRequirements: 'No Special Handling',
        dangerous: 'Not Dangerous',
    };
    const initialValues = {
        pickupLocation: '',
        pickupLocationType: '',
        liftgatePickup: false,
        insidePickup: false,
        deliveryLocation: '',
        deliveryLocationType: '',
        appointmentDelivery: false,
        liftgateDelivery: false,
        insideDelivery: false,
        shipments: [
            {
                handlingUnits: 1,
                description: '',
                dimensions: {
                    length: '',
                    width: '',
                    height: '',
                    weight: '',
                },
            },
        ],
        contactName: '',
        email: ''
    };


    // states ----------------------------------------------------------------
    const [pickupAddress, setPickupAddress] = useState("");
    const [pickupPostalCode, setPickupPostalCode] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [deliveryPostalCode, setDeliveryPostalCode] = useState("");
    const [contactName, setContactName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    const [formError, setFormError] = useState({});
    const [mapError, setMapError] = useState("")
    const [freightError, setFreightError] = useState([]);
    const [displayStyle, setDisplayStyle] = useState('none');
    const [quoteResponse, setQuoteResponse] = useState({});
    const [sortedQuote, setSortedQuote] = useState([]);
    const [show, setShow] = useState(false);
    const [showBook, setShowBook] = useState(false);

    const [pickupMap, setPickupMap] = useState({
        lat: 40.712776,
        lng: -74.005974
    });
    const [deliveryMap, setDeliveryMap] = useState({
        lat: 40.712776,
        lng: -74.005974
    });


    const [pickupLocation, setPickupLocation] = useState({
        postalCode: "",
        city: "",
        province: "",
        country: "",
    })
    const [dropLocation, setDropLocation] = useState({
        postalCode: "",
        city: "",
        province: "",
        country: "",
    })

    const [showPickupMap, setShowPickupMap] = useState(false);
    const [showDeliveryMap, setShowDeliveryMap] = useState(false);
    const [shipments, setShipments] = useState([initialShipment]);
    const [activeIndex, setActiveIndex] = useState([]);



    const instantQouteFormRef = useRef(null)
    const [startDate, setStartDate] = useState(new Date());
    const [currentTab, setCurrentTab] = useState(1);
    const totalSteps = 5;

    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 30);


    // /validations----------------------------------------------------------------
    const tab1ValidationSchema = Yup.object({
        contactName: Yup.string().required('*Contact name is required'),
        email: Yup.string().required('*Email is required').test('email', 'Invalid email', function (value) {
            if (!value) return false; // Handle empty values separately
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Basic email validation regex
        }),
    });
    const tab3ValidationSchema = Yup.object({
        pickupLocation: Yup.string().required('*Pickup location is required'),
    });
    const tab4ValidationSchema = Yup.object({
        deliveryLocation: Yup.string().required('*Delivery location is required'),
    });
    const tab5ValidationSchema = Yup.object({
        shipments: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().required('*Description is required'),
                handlingUnits: Yup.number().min(1, 'Must be at least 1').required('*Required'),
                dimensions: Yup.object().shape({
                    length: Yup.number().min(1, 'Must be positive').required('*Required'),
                    width: Yup.number().min(1, 'Must be positive').required('*Required'),
                    height: Yup.number().min(1, 'Must be positive').required('*Required'),
                    weight: Yup.number().min(1, 'Must be positive').required('*Required'),
                }),
            })
        ),
    });

    const isValidUSZip = (zip) => {
        return /^\d{5}(-\d{4})?$/.test(zip);
    };

    const isValidCanadianPostal = (postal) => {
        return /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/.test(postal);
    };

    const isValidZipOrPostal = (code) => {
        const cleanCode = code.replace(/\s/g, '').toUpperCase();
        return isValidUSZip(cleanCode) || isValidCanadianPostal(cleanCode);
    };

    const formatCanadianPostal = (postal) => {
        if (postal.length === 6) {
            return `${postal.slice(0, 3)} ${postal.slice(3)}`;
        }
        return postal;
    };

    // handle logics ---------------------------------------------------------------- 
    const handleNext = () => {
        if (currentTab < totalSteps) {
            setCurrentTab((prevStep) => prevStep + 1);
        }
    };
    const handlePrevious = () => {
        if (currentTab > 1) {
            setCurrentTab((prevStep) => prevStep - 1);
        }
    };

    const handleClick = (index) => {
        //setIsActive(!isActive); // Toggle the state
        if (activeIndex.includes(index)) {
            let remove = index;
            const updatedItems = activeIndex.filter((item) => item !== remove);
            setActiveIndex(updatedItems);
        }
        if (!activeIndex.includes(index)) {
            setActiveIndex([...activeIndex, index]);
        }
    };

    const handleAddMore = () => {
        setShipments([...shipments, { ...initialShipment }]);
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleBookClose = () => setShowBook(false);


    const formatCustomDate = (inputDate) => {
        const dateObj = moment(inputDate);
        const dayOfMonth = dateObj.date();
        const monthName = dateObj.format("MMMM");
        const year = dateObj.year();

        let daySuffix;
        if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
            daySuffix = "st";
        } else if (dayOfMonth === 2 || dayOfMonth === 22) {
            daySuffix = "nd";
        } else if (dayOfMonth === 3 || dayOfMonth === 23) {
            daySuffix = "rd";
        } else {
            daySuffix = "th";
        }

        return `${dateObj.format("ddd")} ${dateObj.format("DD").replace(/^0/, "")}${daySuffix} ${monthName} ${year}`;
    };

    const markupRate = (quote) => {
        let rate;
        if (Number(quote.totalCharges) < 300 || quote.details.length <= 2) {
            rate = (Number(quote.totalCharges) * 1.33).toFixed(2);
        } else if (quote.details.length > 2) {
            rate = (Number(quote.totalCharges) * 1.22).toFixed(2);
        }
        return rate;
    };

    const extractAddressComponent = (components, type) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component ? component.short_name : null;
    };


    const extractAddressComponentLN = (components, type) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component ? component.long_name : null;
    };


    const fetchGeocode = async (code) => {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            code
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

        try {
            const response = await fetch(geocodeUrl);
            if (!response.ok) {
                console.error("Error fetching geocode! Response was not ok");
                throw new Error('Error fetching geocode data');
            }
            const data = await response.json();
            if (data.status === 'OK' && data.results.length > 0) {
                const result = data.results[0];
                const addressComponents = result.address_components;
                const coordinates = result.geometry.location;
                const locality = extractAddressComponent(addressComponents, 'locality');
                const state = extractAddressComponent(addressComponents, 'administrative_area_level_1');
                const country = extractAddressComponentLN(addressComponents, 'country')

                return { locality, state, coordinates, country };
            } else {
                console.error("Could not find locality or state");
                throw new Error('Could not find locality or state');
            }
        } catch (err) {
            console.error("Could not find locality or state", err);
            throw new Error('Could not find locality or state');
        }
    };


    const handlePickupLocation = async (e) => {
        let code = e.target.value.toUpperCase().replace(/\s/g, '');
        if (isValidCanadianPostal(code)) {
            code = formatCanadianPostal(code);
        }
        setPickupPostalCode(code);
        try {
            if (isValidZipOrPostal(code)) {
                // console.log("code validated");
                setMapError("");
                const locationData = await fetchGeocode(code);
                setPickupAddress(`${locationData?.locality} ${locationData?.state}`);
                setPickupLocation({ postalCode: code, city: locationData?.locality, province: locationData?.state, country: locationData?.country });
                setShowPickupMap(true);
                setPickupMap(locationData.coordinates);
            } else {
                setPickupAddress('');
                setShowPickupMap(false);
                setMapError('Invalid U.S. ZIP code or Canadian postal code');
            }
        } catch (error) {
            setPickupAddress('');
            setMapError(error.message);
        }
    };


    const handleDeliveryLocation = async (e) => {
        let code = e.target.value.toUpperCase().replace(/\s/g, '');
        if (isValidCanadianPostal(code)) {
            code = formatCanadianPostal(code);
        }
        setDeliveryPostalCode(code);
        try {
            if (isValidZipOrPostal(code)) {
                // console.log("code validated");
                const locationData = await fetchGeocode(code);
                setDeliveryAddress(`${locationData?.locality} ${locationData?.state}`);
                setDropLocation({ postalCode: code, city: locationData?.locality, province: locationData?.state, country: locationData?.country });
                setDeliveryMap(locationData.coordinates);
                setShowDeliveryMap(true);
                setMapError("")
            } else {
                setDeliveryAddress('');
                setShowDeliveryMap(false);
                setMapError('Invalid U.S. ZIP code or Canadian postal code');
            }
        } catch (error) {
            setDeliveryAddress('');
            setMapError(error.message || 'An unexpected error occurred');
        }
    };

    const sendBookNotification = async (index) => {
        try {
            const requestBody = {
                "contactName": contactName,
                "email": email,
                "quote": quoteResponse.quotes[index]
            };
            const headers = {
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/book/quote`, requestBody, {
                headers: headers
            });
            if (response.data) {
                setShowBook(true);
                // console.log('Message:', response.data.message);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleQouteSubmit = async (data) => {
        // console.log(data, "data from handleQouteSubmit------before");
        setEmail(data?.email)
        setContactName(data?.contactName)
        if (data?.contactName && data?.email && data?.pickupLocation && data?.deliveryLocation && data?.shipments[0]?.dimensions?.height) {
            try {
                const pickupDate = moment(startDate).format('YYYY-MM-DD[T]00:00:00');
                const details = [];
                setFreightError('');
                // console.log(isLoading);
                data.shipments.forEach((shipment) => {
                    details.push({
                        "commodity": "COM",
                        "description": shipment.description,
                        "length": Number(shipment.dimensions.length),
                        "lengthUnits": "IN",
                        "width": Number(shipment.dimensions.width),
                        "widthUnits": "IN",
                        "height": Number(shipment.dimensions.height),
                        "heightUnits": "IN",
                        "weight": Number(shipment.dimensions.weight),
                        "weightUnits": "LB",
                        "pieces": Number(shipment.handlingUnits),
                        "piecesUnits": "SKD",
                        "requestedEquipment": "LTL",
                        "dangerous": shipment.dangerous === "DANGER" ? "Y" : "N",
                        "stackable": shipment.stackable === "STACKABLE" ? "Y" : "N",
                        "temperatureRequirements": shipment.temperatureRequirements,
                        "nmfcCode": shipment.nmfcCode
                    });
                });
                const requestBody = {
                    "contactName": contactName,
                    "email": email,
                    "orders": [
                        {
                            "details": details,
                            "appointmentDelivery": data.appointmentDelivery,

                            "liftgatePickup": data.liftgatePickup,
                            "liftgateDelivery": data.liftgateDelivery,


                            "insidePickup": data.insidePickup,
                            "insideDelivery": data.insideDelivery,

                            "pickupLocationType": data.pickupLocationType,
                            "deliveryLocationType": data.deliveryLocationType,

                            "startZone": pickupPostalCode,
                            "startZoneCity": pickupLocation?.city,
                            "startZoneProvince": pickupLocation?.province,
                            "startZoneCountry": pickupLocation?.country,

                            "endZone": deliveryPostalCode,
                            "endZoneCity": dropLocation?.city,
                            "endZoneProvince": dropLocation?.province,
                            "endZoneCountry": dropLocation?.country,

                            "pickUpBy": pickupDate,
                            "pickUpByEnd": pickupDate,
                            "deliverBy": pickupDate,
                            "deliverByEnd": pickupDate
                        }

                    ]
                };
                setIsLoading(true);
                const headers = {
                    'Content-Type': 'application/json'
                };

                // console.log(requestBody, "requestBody");


                const response = await axios.post(`${process.env.REACT_APP_API_URL}/quotes`, requestBody, {
                    headers: headers
                });
                console.log('Response:', response.data);
                setIsLoading(false);
                // console.log(isLoading);

                // if (response.data.quotes && response.data.quotes.length > 0) {
                //     setQuoteResponse(response.data);
                //     setDisplayStyle('block');
                // } else {
                //     if (response.data.errors && response.data.errors.length > 0) {
                //         setDisplayStyle('none');
                //         setFreightError(response.data.errors);
                //     } else {
                //         setDisplayStyle('none');
                //         handleShow();
                //     }
                // }

                if (response?.data?.quotes && response.data.quotes.length > 0) {
                    setQuoteResponse(response?.data);
                    setDisplayStyle('block');
                } else {
                    setDisplayStyle('none');
                }

                if (response?.data?.errors) {
                    setFreightError(response?.data?.errors);
                } else {
                    setDisplayStyle('block');
                    handleShow();
                }
            } catch (e) {
                console.log(e);
                setDisplayStyle('none');
                //setFreightError(e.response.data.errors);
                setIsLoading(false);
            }
        };
    }


    console.log(quoteResponse, "quoteResponse");
    console.log(freightError, "freightError");

    useEffect(() => {

        if (quoteResponse?.quotes) {
            const sortTheQuote = quoteResponse?.quotes.sort((a, b) => parseFloat(a.totalCharges) - parseFloat(b.totalCharges));
            setSortedQuote(sortTheQuote)
        }


    }, [quoteResponse])




    return (
        <>
            <Header />
            <div className="home-page-container">
                {/* banner */}
                <div className="main-banner-container">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-12 col-md-12 col-lg-7 h-100">
                                <div className="main-banner-wrapper">
                                    <h1 className="banner-heading">Instant Cross-<br />Border North<br />American & Canada-<br />Wide LTL Quotes!</h1>
                                    <h4 className="banner-small-heading">EFFORTLESS SHIPPING SOLUTIONS FOR <br />1-4 SKID LOADS</h4>
                                    <a href="#qouteForm" className="theme-btn btn-blue">Get a quote</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* banner layer */}
                <div className="banner-layer-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="banner-layer-wrapper">
                                    <img className="img-fluid" src={Icon1} alt="Img" />
                                    <h4>No signup<br />quoting</h4>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="banner-layer-wrapper">
                                    <img className="img-fluid" src={Icon2} alt="Img" />
                                    <h4>Shop freight quotes competitively for the best option and book today.</h4>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="banner-layer-wrapper">
                                    <img className="img-fluid" src={Icon3} alt="Img" />
                                    <h4>Your trusted partner for Cross-Border Shipping</h4>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="banner-layer-wrapper">
                                    <img className="img-fluid" src={Icon4} alt="Img" />
                                    <h4>Instantly compare high quality carriers and competitive shipping rates.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* get instant quote */}
                <div className="section-instantquote" id="qouteForm">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="instantquote-form-container">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-md-11 col-lg-11">
                                            <div className="section-heading-wrapper">
                                                <h2 className="section-heading">Get Instant Quotes</h2>
                                                <p className="section-description">Enter your shipment details to get accurate quotes from dozens of the best carriers in North America.</p>
                                                <p className="section-italic-description">⚠️<span>FreightQuote does not handle small packages, parcels, vehicles, or home moves.</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="instantquote-tabs-wrapper">
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="contact">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <Nav variant="pills" className="quoteNav">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey={1} active={currentTab === 1}>
                                                                        <div className="quoteNav-icons">
                                                                            <img className="img-fluid inactive" src={contactgray} alt="Img" />
                                                                            <img className="img-fluid active" src={contactwhite} alt="Img" />
                                                                        </div>
                                                                        <p>Contact Details</p>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey={2} active={currentTab === 2}>
                                                                        <div className="quoteNav-icons">
                                                                            <img className="img-fluid inactive" src={dategray} alt="Img" />
                                                                            <img className="img-fluid active" src={datewhite} alt="Img" />
                                                                        </div>
                                                                        <p>Pickup Date</p>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey={3} active={currentTab === 3}>
                                                                        <div className="quoteNav-icons">
                                                                            <img className="img-fluid inactive" src={plocationgray} alt="Img" />
                                                                            <img className="img-fluid active" src={plocationwhite} alt="Img" />
                                                                        </div>
                                                                        <p>Pickup Location</p>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey={4} active={currentTab === 4}>
                                                                        <div className="quoteNav-icons">
                                                                            <img className="img-fluid inactive" src={dlocationgray} alt="Img" />
                                                                            <img className="img-fluid active" src={dlocationwhite} alt="Img" />
                                                                        </div>
                                                                        <p>Delivery Location</p>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey={5} active={currentTab === 5}>
                                                                        <div className="quoteNav-icons">
                                                                            <img className="img-fluid inactive" src={shipmentgray} alt="Img" />
                                                                            <img className="img-fluid active" src={shipmentwhite} alt="Img" />
                                                                        </div>
                                                                        <p>Shipment Contents</p>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </Col>
                                                        <Col sm={12}>

                                                            <Formik
                                                                enableReinitialize={true}
                                                                innerRef={instantQouteFormRef}
                                                                initialValues={initialValues}
                                                                validationSchema={
                                                                    currentTab === 1 ? tab1ValidationSchema :
                                                                        currentTab === 3 ? tab3ValidationSchema :
                                                                            currentTab === 4 ? tab4ValidationSchema :
                                                                                currentTab === 5 ? tab5ValidationSchema : null
                                                                }

                                                                onSubmit={(values) => {
                                                                    // console.log(values, "formik_values");
                                                                    try {
                                                                        if (Object.keys(formError).length === 0) {
                                                                            handleQouteSubmit(values);
                                                                        };
                                                                    }
                                                                    catch (error) {
                                                                        console.log(error);
                                                                    }
                                                                }}
                                                            >
                                                                {({ errors, touched, values, handleChange, handleBlur, handleSubmit, validateForm, setFieldValue }) => (
                                                                    <Form>
                                                                        <Tab.Content>
                                                                            <Tab.Pane eventKey={1} active={currentTab === 1}>
                                                                                <div className="row align-items-cnter justify-content-center">
                                                                                    <div className="col-12 col-md-11 col-lg-11">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <div className="form-input-group">
                                                                                                    <label className="form-label" htmlFor="label_name" >Contact Name</label>
                                                                                                    <Field type="text" name="contactName" className="form-input" placeholder="Enter contact name" />
                                                                                                    <ErrorMessage className="formik_error_comp" name="contactName" component="div" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <div className="form-input-group">
                                                                                                    <label className="form-label" htmlFor="label_name" >Email</label>
                                                                                                    <Field type="email" name="email" className="form-input" placeholder="Enter your email" />
                                                                                                    <ErrorMessage className="formik_error_comp" name="email" component="div" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey={2} active={currentTab === 2}>
                                                                                <div className="row align-items-cnter justify-content-center">
                                                                                    <div className="col-12 col-md-11 col-lg-11 mb-4">
                                                                                        <div className="datepicker">
                                                                                            <DatePicker
                                                                                                name="pickupDate"
                                                                                                inline
                                                                                                selected={startDate}
                                                                                                onChange={(date) => setStartDate(date)}
                                                                                                monthsShown={2}
                                                                                                minDate={today}
                                                                                                maxDate={maxDate}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey={3} active={currentTab === 3}>
                                                                                <div className="row align-items-cnter justify-content-center">
                                                                                    <div className="col-12 col-md-11 col-lg-11 mb-4">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <div className="form-input-group">
                                                                                                    <label className="form-label" htmlFor="label_name" >Pickup Postal Code / Zip Code</label>
                                                                                                    <div className="postalCode-city-inputs">
                                                                                                        <Field
                                                                                                            name='pickupLocation'
                                                                                                            className="form-input-half"
                                                                                                            type="text"
                                                                                                            placeholder="Enter postal code"
                                                                                                            value={pickupPostalCode}
                                                                                                            onBlur={handleBlur}
                                                                                                            onChange={(e) => {
                                                                                                                handleChange(e);
                                                                                                                handlePickupLocation(e);
                                                                                                            }}
                                                                                                        />
                                                                                                        <input className="form-input-half" type="text" placeholder="" value={pickupAddress} readOnly />
                                                                                                    </div>
                                                                                                    <ErrorMessage className="formik_error_comp" name="pickupLocation" component="div" />

                                                                                                    {mapError && (<span className="error-msg">{mapError}</span>)}

                                                                                                    <div className="inputMap">
                                                                                                        <MapComponent lat={pickupMap.lat} lng={pickupMap.lng} location={pickupAddress} visible={showPickupMap} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-input-group mb-0">
                                                                                                    <label className="form-label" htmlFor="label_name" >Pickup Location Type<i className="fa fa-question-circle-o picklocTypeHover"></i>
                                                                                                        <div className="info-box picklocTypeDisplay">
                                                                                                            <h3>What type of location is the pickup address?</h3>
                                                                                                            <p>Make sure you enter the correct location type, as it affects the quote.</p>
                                                                                                            <p>If at pickup, the location type is different than the type you specified, you may incur an additional charge.</p>
                                                                                                        </div>
                                                                                                    </label>

                                                                                                    <Field component="select" name="pickupLocationType" className="form-input" multiple={false}>
                                                                                                        <option value="Warehouse">Warehouse</option>
                                                                                                        <option value="Grocery Warehouse">Grocery Warehouse</option>
                                                                                                        <option value="Residential">Residential</option>
                                                                                                        <option value="Construction Site">Construction Site</option>
                                                                                                    </Field>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <div className="form-input-group">
                                                                                                    <label className="form-label" htmlFor="label_name" >Logistics</label>
                                                                                                    <div className="form-checkbox-list">
                                                                                                        <div className="form-checkbox-item">
                                                                                                            <label className="form-checkbox-wrapper">
                                                                                                                <div className="form-checkbox-info">
                                                                                                                    <h3>Lift gate required on truck<i className="fa fa-question-circle-o truckHover"></i>
                                                                                                                        <div className="info-box truckDisplay">
                                                                                                                            <h3>Lift gate required at pickup</h3>
                                                                                                                            <p>Check this box if you are unable to load the freight onto the truck at your pickup location. For example, if you don't have a dock, loading bay, or a forklift.</p>
                                                                                                                        </div>
                                                                                                                    </h3>
                                                                                                                    <p>The pickup location does not have a loading dock</p>
                                                                                                                </div>
                                                                                                                <Field type="checkbox" name="liftgatePickup" />
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="form-checkbox-item">
                                                                                                            <label className="form-checkbox-wrapper">
                                                                                                                <div className="form-checkbox-info">
                                                                                                                    <h3>Pickup inside the location<i className="fa fa-question-circle-o insideHover"></i>
                                                                                                                        <div className="info-box insideDisplay">
                                                                                                                            <h3>Inside pickup</h3>
                                                                                                                            <p>The driver is required to go beyond the immediate area of the receiving door to pickup the freight.</p>
                                                                                                                        </div>
                                                                                                                    </h3>
                                                                                                                    <p>The freight needs to be brought from inside the building</p>
                                                                                                                </div>
                                                                                                                <Field type="checkbox" name="insidePickup" />
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey={4} active={currentTab === 4}>
                                                                                <div className="row align-items-cnter justify-content-center">
                                                                                    <div className="col-12 col-md-11 col-lg-11 mb-4">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <div className="form-input-group">
                                                                                                    <label className="form-label" htmlFor="label_name" >Delivery Postal Code / Zip Code</label>

                                                                                                    <div className="postalCode-city-inputs">
                                                                                                        <Field name="deliveryLocation" className="form-input-half" type="text" placeholder="Enter postal code" value={deliveryPostalCode} onChange={(e) => {
                                                                                                            handleChange(e);
                                                                                                            handleDeliveryLocation(e);
                                                                                                        }}
                                                                                                            onBlur={handleBlur}
                                                                                                        />
                                                                                                        <input className="form-input-half" type="text" placeholder="" value={deliveryAddress} readOnly />
                                                                                                    </div>
                                                                                                    {touched.deliveryLocation && errors.deliveryLocation && (
                                                                                                        <span className="error-msg">{errors.deliveryLocation}</span>
                                                                                                    )}

                                                                                                    <input
                                                                                                        type="hidden"
                                                                                                        placeholder="Delivery Postal Code"
                                                                                                        value={deliveryPostalCode}
                                                                                                        readOnly
                                                                                                    />
                                                                                                    <div className="inputMap">
                                                                                                        <MapComponent lat={deliveryMap.lat} lng={deliveryMap.lng} location={deliveryAddress} visible={showDeliveryMap} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-input-group mb-0">
                                                                                                    <label className="form-label" htmlFor="label_name" >Delivery Location Type
                                                                                                        <i className="fa fa-question-circle-o deliveryTypeHover"></i>
                                                                                                        <div className="info-box deliveryTypeDisplay">
                                                                                                            <h3>What type of location is the delivery address?</h3>
                                                                                                            <p>Make sure you enter the correct location type, as it affects the quote.</p>
                                                                                                            <p>If at delivery, the location type is different than the type you specified, you may incur an additional charge.</p>
                                                                                                        </div>
                                                                                                    </label>
                                                                                                    <Field component="select" name="deliveryLocationType" className="form-input" multiple={false}>
                                                                                                        <option value="Warehouse">Warehouse</option>
                                                                                                        <option value="Grocery Warehouse">Grocery Warehouse</option>
                                                                                                        <option value="Residential">Residential</option>
                                                                                                        <option value="Construction Site">Construction Site</option>
                                                                                                    </Field>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <div className="form-input-group">
                                                                                                    <label className="form-label" htmlFor="label_name" >Scheduling</label>
                                                                                                    <div className="form-checkbox-list">
                                                                                                        <div className="form-checkbox-item">
                                                                                                            <label className="form-checkbox-wrapper">
                                                                                                                <div className="form-checkbox-info">
                                                                                                                    <h3>Delivery appointment required
                                                                                                                        <i className="fa fa-question-circle-o deliveryAppiontHover"></i>
                                                                                                                        <div className="info-box deliveryAppiontDisplay">
                                                                                                                            <h3>Delivery appointment required at delivery</h3>
                                                                                                                            <p>Check this box if you need the carrier to deliver within a specific time window or two hours or less. They will contact the delivery contact to confirm the time.</p>
                                                                                                                        </div>
                                                                                                                    </h3>
                                                                                                                    <p>The location requires the carrier to make a delivery appointment</p>
                                                                                                                </div>
                                                                                                                <Field type="checkbox" name="appointmentDelivery" />
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-input-group mb-0">
                                                                                                    <label className="form-label" htmlFor="label_name" >Logistics</label>
                                                                                                    <div className="form-checkbox-list">
                                                                                                        <div className="form-checkbox-item">
                                                                                                            <label className="form-checkbox-wrapper">
                                                                                                                <div className="form-checkbox-info">
                                                                                                                    <h3>Lift gate required on truck
                                                                                                                        <i className="fa fa-question-circle-o deliveryTruckHover"></i>
                                                                                                                        <div className="info-box deliveryTruckDisplay">
                                                                                                                            <h3>Lift gate required at delivery</h3>
                                                                                                                            <p>Check this box if you are unable to unload the freight off the truck at your delivery location. For example, if you don't have a dock, loading bay, or a forklift.</p>
                                                                                                                        </div>
                                                                                                                    </h3>
                                                                                                                    <p>The delivery location does not have a loading dock</p>
                                                                                                                </div>
                                                                                                                <Field type="checkbox" name="liftgateDelivery" />
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="form-checkbox-item">
                                                                                                            <label className="form-checkbox-wrapper">
                                                                                                                <div className="form-checkbox-info">
                                                                                                                    <h3>Delivery inside the location
                                                                                                                        <i className="fa fa-question-circle-o deliveryInsideHover"></i>
                                                                                                                        <div className="info-box deliveryInsideDisplay">
                                                                                                                            <h3>Inside delivery</h3>
                                                                                                                            <p>The driver is required to go beyond the immediate area of the receiving door to deliver the freight.</p>
                                                                                                                        </div>
                                                                                                                    </h3>
                                                                                                                    <p>The freight needs to be brought inside the building</p>
                                                                                                                </div>
                                                                                                                <Field type="checkbox" name="insideDelivery" />
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey={5} active={currentTab === 5}>
                                                                                <FieldArray name="shipments">
                                                                                    {({ push, remove, form }) => (
                                                                                        <React.Fragment>
                                                                                            {values.shipments.map((shipment, index) => (
                                                                                                <div className="row align-items-cnter justify-content-center mb-5" key={index}>
                                                                                                    <div className="col-12 col-md-11 col-lg-11">
                                                                                                        <div className="row">
                                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                                <div className="form-input-group">
                                                                                                                    <label className="form-label" htmlFor="label_name" >Handling Units
                                                                                                                        <i className="fa fa-question-circle-o handUnitHover"></i>
                                                                                                                        <div className="info-box handUnitDisplay">
                                                                                                                            <h3>Handling Units</h3>
                                                                                                                            <p>Select how many of the same sized handling units you have.</p>
                                                                                                                        </div>
                                                                                                                    </label>
                                                                                                                    <div className="unit-selection">
                                                                                                                        <div className="unit-increase-decrease">
                                                                                                                            <Field
                                                                                                                                type="number"
                                                                                                                                className="form-input"
                                                                                                                                name={`shipments[${index}].handlingUnits`}
                                                                                                                                readOnly />

                                                                                                                            {touched.shipments &&
                                                                                                                                touched.shipments[index] &&
                                                                                                                                errors.shipments &&
                                                                                                                                errors.shipments[index]?.handlingUnits && (
                                                                                                                                    <span className="error-msg">
                                                                                                                                        {errors.shipments[index]?.handlingUnits}
                                                                                                                                    </span>
                                                                                                                                )}
                                                                                                                        </div>
                                                                                                                        <Field as="select" className="form-input" value={shipment.locationType}>
                                                                                                                            <option value="SKD">Pallet</option>
                                                                                                                        </Field>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-input-group mb-0">
                                                                                                                    <label className="form-label" htmlFor="label_name" >What is on this pallet?</label>

                                                                                                                    <div className="info-with-input">
                                                                                                                        <span>Description
                                                                                                                            <i className="fa fa-question-circle-o palletDescripHover"></i>
                                                                                                                            <div className="info-box palletDescripDisplay">
                                                                                                                                <h3>Pallet Content</h3>
                                                                                                                                <p>Enter a accurate description of what is on each pallet.</p>
                                                                                                                                <p>A vague description can cause delays if your shipment is crossing the border.</p>
                                                                                                                                <p>Each carrier maintains their own list of prohibited items. If you have any doubt if something is suitable to ship please contact us, and we can advise.</p>
                                                                                                                            </div>
                                                                                                                        </span>

                                                                                                                        <Field
                                                                                                                            type="text"
                                                                                                                            className="form-input"
                                                                                                                            name={`shipments[${index}].description`}
                                                                                                                            placeholder="Enter description"
                                                                                                                        />
                                                                                                                        {touched.shipments &&
                                                                                                                            touched.shipments[index] &&
                                                                                                                            errors.shipments &&
                                                                                                                            errors.shipments[index]?.description && (
                                                                                                                                <span className="error-msg">
                                                                                                                                    {errors.shipments[index]?.description}
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                    </div>

                                                                                                                    <div className="info-with-input">
                                                                                                                        <span>NMFC Code
                                                                                                                            <i className="fa fa-question-circle-o nmfcHover"></i>
                                                                                                                            <div className="info-box nmfcDisplay">
                                                                                                                                <h3>(Optional) NMFC Code</h3>
                                                                                                                                <p>If you have one, enter a 5-6 digit NMFC code here to help carriers under the type of freight you are shipping more easily. With an NMFC code provided some carriers will be able to offer cheaper quotes.</p>
                                                                                                                                <p> Using LiveFreightQuote, you don't need to worry about the freight class (eg. 50, 70, 110, etc), since we calculate it for you.</p>
                                                                                                                                <p>Our team can help you figure out the correct NMFC code for your shipment.</p>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                        <Field
                                                                                                                            className="form-input"
                                                                                                                            type="text"
                                                                                                                            placeholder="If known, enter the NMFC Code"
                                                                                                                            name={`shipments[${index}].nmfcCode`}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                                <div className="form-input-group">
                                                                                                                    <label className="form-label" htmlFor="label_name" >What are the dimensions for each pallet?
                                                                                                                        <i className="fa fa-question-circle-o dimensionsHover"></i>
                                                                                                                        <div className="info-box dimensionsDisplay">
                                                                                                                            <h3>Dimensions</h3>
                                                                                                                            <p>Correct dimensions ensure you get accurate quotes from carriers.</p>
                                                                                                                            <p>If you are shipping multiple identical pallets , this should be the dimensions and weight of each single pallet.</p>
                                                                                                                            <p>Please add an additional row if you are shipping multiple pallets with different products, dimensions or weights.</p>
                                                                                                                            <p>Maximum handling unit dimensions: 50ft long x 96" wide x 102" high</p>
                                                                                                                        </div>
                                                                                                                    </label>

                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12 col-md-6 col-lg-3">
                                                                                                                            <div className="info-with-input">
                                                                                                                                <p>Length (in)</p>
                                                                                                                                <Field
                                                                                                                                    type="number"
                                                                                                                                    className="form-input box-dimension"
                                                                                                                                    name={`shipments[${index}].dimensions.length`}
                                                                                                                                    min="1" />
                                                                                                                                {touched.shipments &&
                                                                                                                                    touched.shipments[index] &&
                                                                                                                                    errors.shipments &&
                                                                                                                                    errors.shipments[index]?.dimensions?.length && (
                                                                                                                                        <span className="error-msg">
                                                                                                                                            {errors.shipments[index]?.dimensions?.length}
                                                                                                                                        </span>
                                                                                                                                    )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-12 col-md-6 col-lg-3">
                                                                                                                            <div className="info-with-input">
                                                                                                                                <p>Width (in)</p>
                                                                                                                                <Field
                                                                                                                                    type="number"
                                                                                                                                    className="form-input box-dimension"
                                                                                                                                    name={`shipments[${index}].dimensions.width`}
                                                                                                                                    min="1" />
                                                                                                                                {touched.shipments &&
                                                                                                                                    touched.shipments[index] &&
                                                                                                                                    errors.shipments &&
                                                                                                                                    errors.shipments[index]?.dimensions?.width && (
                                                                                                                                        <span className="error-msg">
                                                                                                                                            {errors.shipments[index]?.dimensions?.width}
                                                                                                                                        </span>
                                                                                                                                    )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-12 col-md-6 col-lg-3">
                                                                                                                            <div className="info-with-input">
                                                                                                                                <p>Height (in)</p>
                                                                                                                                <Field
                                                                                                                                    type="number"
                                                                                                                                    className="form-input box-dimension"
                                                                                                                                    name={`shipments[${index}].dimensions.height`}
                                                                                                                                    min="1" />
                                                                                                                                {touched.shipments &&
                                                                                                                                    touched.shipments[index] &&
                                                                                                                                    errors.shipments &&
                                                                                                                                    errors.shipments[index]?.dimensions?.height && (
                                                                                                                                        <span className="error-msg">
                                                                                                                                            {errors.shipments[index]?.dimensions?.height}
                                                                                                                                        </span>
                                                                                                                                    )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-12 col-md-6 col-lg-3">
                                                                                                                            <div className="info-with-input">
                                                                                                                                <p>Weight (lb)</p>
                                                                                                                                <Field
                                                                                                                                    type="number"
                                                                                                                                    className="form-input box-dimension"
                                                                                                                                    name={`shipments[${index}].dimensions.weight`}
                                                                                                                                    min="1" />
                                                                                                                                {touched.shipments &&
                                                                                                                                    touched.shipments[index] &&
                                                                                                                                    errors.shipments &&
                                                                                                                                    errors.shipments[index]?.dimensions?.weight && (
                                                                                                                                        <span className="error-msg">
                                                                                                                                            {errors.shipments[index]?.dimensions?.weight}
                                                                                                                                        </span>
                                                                                                                                    )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="form-input-group mb-0">
                                                                                                                    <label className="form-label" htmlFor="label_name" >Special Handling</label>

                                                                                                                    <div className="info-with-input">
                                                                                                                        <span>Is Stackable?
                                                                                                                            <i className="fa fa-question-circle-o stackHover"></i>
                                                                                                                            <div className="info-box stackDisplay">
                                                                                                                                <h3>Stackable</h3>
                                                                                                                                <p>Is your freight stackable? Allowing freight to be stacked can greatly increase the risk of damages, however in some circumstances it might lead to lower prices.</p>
                                                                                                                                <p>If in doubt, we recommend marking it as not stackable.</p>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                        <select
                                                                                                                            className="form-input"
                                                                                                                            value={shipment?.stackable}
                                                                                                                            onChange={(e) => {
                                                                                                                                setFieldValue(`shipments.${index}.stackable`, e.target.value);
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <option>Not Stackable</option>
                                                                                                                            <option>Pallet is stackable</option>
                                                                                                                        </select>
                                                                                                                    </div>

                                                                                                                    <div className="info-with-input">
                                                                                                                        <span>Temperature Requirements
                                                                                                                            <i className="fa fa-question-circle-o tempHover"></i>
                                                                                                                            <div className="info-box tempDisplay">
                                                                                                                                <h3>Stackable</h3>
                                                                                                                                <p>If your shipment requires protection from freezing, or refridgerated or frozen handling you can specify it here.</p>
                                                                                                                                <p>Unfortunatley we don't currently support refridgerated or frozen shipments</p>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                        <select
                                                                                                                            className="form-input"
                                                                                                                            value={shipment.temperatureRequirements}
                                                                                                                            onChange={(e) => {
                                                                                                                                setFieldValue(`shipments.${index}.temperatureRequirements`, e.target.value);
                                                                                                                            }}
                                                                                                                        // onChange={(e) => handleChange(index, 'temperatureRequirements', e.target.value)}
                                                                                                                        >
                                                                                                                            <option>No Special Handling</option>
                                                                                                                            <option>Protect From Freezing</option>
                                                                                                                            <option>Keep Refrigerated</option>
                                                                                                                            <option>Keep Frozen</option>
                                                                                                                        </select>
                                                                                                                    </div>

                                                                                                                    <div className="info-with-input">
                                                                                                                        <span>Dangerous?
                                                                                                                            <i className="fa fa-question-circle-o dangerHover"></i>
                                                                                                                            <div className="info-box dangerDisplay">
                                                                                                                                <h3>Dangerous</h3>
                                                                                                                                <p>If your freight is dangerous/hazardous the carrier needs to know about it. Please enter details.</p>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                        <select
                                                                                                                            className="form-input"
                                                                                                                            value={shipment.dangerous}
                                                                                                                            // onChange={(e) => handleChange(index, 'dangerous', e.target.value)}
                                                                                                                            onChange={(e) => {
                                                                                                                                setFieldValue(`shipments.${index}.dangerous`, e.target.value);
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <option>Not Dangerous</option>
                                                                                                                            <option>Dangerous</option>
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-12 col-md-12">
                                                                                                                {index > 0 && (
                                                                                                                    <button type="button" className="remove-btn" onClick={() => remove(index)}>
                                                                                                                        <i className="fa fa-trash"></i>Remove
                                                                                                                    </button>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}

                                                                                            <div className="row justify-content-center">
                                                                                                <div className="col-12 col-md-11 col-lg-11">
                                                                                                    <div className="tabsubmit-btn-wrapper">
                                                                                                        <div className="tabsubmit-btn-left">
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="addNewContent-btn"
                                                                                                                onClick={() => {
                                                                                                                    push(initialValues.shipments[0]);
                                                                                                                    handleAddMore();
                                                                                                                }}
                                                                                                            // data-bs-toggle="dropdown"
                                                                                                            // aria-expanded="false"
                                                                                                            >
                                                                                                                <i className="fa fa-plus-circle"></i>
                                                                                                                Add more to this shipment
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}

                                                                                </FieldArray>
                                                                            </Tab.Pane>

                                                                            <div className="col-12 col-md-11 col-lg-11">
                                                                                <div className="tabsubmit-btn-wrapper">
                                                                                    <div className="tabsubmit-btn-left"></div>
                                                                                    <div className="tabsubmit-btn-right">
                                                                                        {currentTab !== 1 && <button className="theme-btn btn-back" type="button" onClick={() => handlePrevious()}>Back</button>}
                                                                                        {currentTab !== totalSteps && <button
                                                                                            className="theme-btn btn-next"
                                                                                            type="button"
                                                                                            onClick={async (e) => {
                                                                                                e.preventDefault();

                                                                                                handleSubmit(e)
                                                                                                // Validate the form and get errors
                                                                                                const errors = await validateForm();
                                                                                                setFormError(errors);

                                                                                                // Navigate to the next tab if there are no validation errors
                                                                                                if (Object.keys(errors).length === 0) {
                                                                                                    handleNext();
                                                                                                }
                                                                                            }}
                                                                                        >Next
                                                                                        </button>}
                                                                                        {currentTab === totalSteps && <button
                                                                                            className="theme-btn btn-next"
                                                                                            type="submit"
                                                                                            disabled={isLoading}
                                                                                            onClick={async (e) => {
                                                                                                e.preventDefault();

                                                                                                // Validate the form
                                                                                                const errors = await validateForm();
                                                                                                setFormError(errors);

                                                                                                // Submit the form if no errors
                                                                                                if (Object.keys(errors).length === 0) {
                                                                                                    await handleSubmit(e);
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {isLoading ? (
                                                                                                <>
                                                                                                    <span>Submitting...</span>
                                                                                                    <div className="buttonSpinnerBlock">
                                                                                                        <span className="buttonSpinner"></span>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    Get a quote
                                                                                                </>
                                                                                            )}

                                                                                        </button>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Tab.Content>
                                                                    </Form>
                                                                )}
                                                            </Formik>

                                                            {/* Quote Result */}
                                                            <div className="quote-result-container" style={{ display: displayStyle }}>
                                                                <div className="row align-items-center justify-content-center">
                                                                    <div className="col-12 col-md-11 col-lg-11">
                                                                        <div className="quotes-heading">
                                                                            <h3>Quotes</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-11 col-lg-11">
                                                                        <div className="quote-list">
                                                                            {sortedQuote && sortedQuote?.map((quote, index) => (
                                                                                <div className="quote-item" key={index}>
                                                                                    <div className="quote-main-content">
                                                                                        <div><p>&nbsp;</p></div>
                                                                                        {!activeIndex.includes(index) ? (
                                                                                            <div className={`quote-item-content`}>

                                                                                                <div className="quote-item-delivery">
                                                                                                    <p className="quote-item-heading">Earliest Delivery</p>
                                                                                                    <h2 className="quote-item-result">{formatCustomDate(quote.deliveryDate)}</h2>
                                                                                                </div>
                                                                                                <div className="quote-item-transit">
                                                                                                    <p className="quote-item-heading">Transit</p>
                                                                                                    <h2 className="quote-item-result">
                                                                                                        {quote.transitTime}
                                                                                                        <span className="plus">+</span>
                                                                                                        <span className="days">Business<br />Days</span>
                                                                                                    </h2>
                                                                                                </div>
                                                                                                <div className="quote-item-price">
                                                                                                    <h1><span className="price-dollar">$</span>{markupRate(quote)}<span
                                                                                                        className="price-currency">{quote.currencyCode}</span></h1>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : null}
                                                                                        <div className="quote-drop-wrapper">
                                                                                            {/* <!-- collapse --> */}
                                                                                            <button onClick={() => handleClick(index)} className="quoteDownbtn" type="button"
                                                                                                data-bs-toggle="collapse"
                                                                                                data-bs-target={`#collapseExample-${index}`} aria-expanded="false"
                                                                                                aria-controls={`collapseExample-${index}`}></button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="quote-item-dropcontent">
                                                                                        <div className="collapse" id={`collapseExample-${index}`}>
                                                                                            <div className="quote-collapse-content">
                                                                                                <div className="qcc-head">
                                                                                                    <div className="qcc-head-left">
                                                                                                        <h2>Transit: {quote.transitTime}+ Business Days</h2>
                                                                                                        <p>Earliest Delivery: {formatCustomDate(quote.deliveryDate)}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="qcc-head-right">
                                                                                                        <h2><span className="qcc-dollar">$</span>{markupRate(quote)}<span
                                                                                                            className="qcc-currency">{quote.currencyCode}</span></h2>
                                                                                                        <p>Inclusive of all taxes</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                                    <Button variant="" onClick={() => sendBookNotification(index)} className="theme-btn btn-main mt-2">Book</Button>
                                                                                                </div>
                                                                                                <div className="qcc-body">
                                                                                                    <div className="qcc-body-list pickup">
                                                                                                        <div className="qcc-body-list-heading">
                                                                                                            <h3>Pickup</h3>
                                                                                                        </div>
                                                                                                        <div className="qcc-details">
                                                                                                            <div className="qcc-detail-item">
                                                                                                                <h4 className="qcc-detail-small-heading">
                                                                                                                    When</h4>
                                                                                                                <h3 className="qcc-detail-result-heading">
                                                                                                                    {formatCustomDate(quote.pickUpBy)}</h3>
                                                                                                                <p>Pickup window: {moment(quote.pickUpBy).format("ha")}-{moment(quote.pickUpByEnd).format("ha")}</p>
                                                                                                            </div>
                                                                                                            <div className="qcc-detail-item">
                                                                                                                <h4 className="qcc-detail-small-heading">
                                                                                                                    Where</h4>
                                                                                                                <h3 className="qcc-detail-result-heading">
                                                                                                                    {quote.startZoneDescription}</h3>
                                                                                                                {/* <p>Warehouse</p> */}
                                                                                                            </div>
                                                                                                            <div className="qcc-detail-item">
                                                                                                                <h4 className="qcc-detail-small-heading">
                                                                                                                    Payload</h4>
                                                                                                                <h3 className="qcc-detail-result-heading">
                                                                                                                    Total Weight: {quote.totalWeight}lb</h3>
                                                                                                                {quote.details.map((detail, index) => (
                                                                                                                    <React.Fragment key={index}>
                                                                                                                        <p>{detail.pieces} X {detail.piecesUnits} {detail.description}</p>
                                                                                                                        <p>{detail.length}" X {detail.width}" x {detail.height}"</p>
                                                                                                                    </React.Fragment>
                                                                                                                ))}
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="qcc-body-list transit">
                                                                                                        <div className="qcc-body-list-heading">
                                                                                                            <h3>Transit</h3>
                                                                                                        </div>
                                                                                                        <div className="qcc-details">
                                                                                                            <div className="qcc-detail-item">
                                                                                                                <h4 className="qcc-detail-small-heading">
                                                                                                                    Carrier</h4>
                                                                                                                <p>{quote.carrierName}</p>
                                                                                                            </div>

                                                                                                            <div className="qcc-detail-item">
                                                                                                                <h4 className="qcc-detail-small-heading">
                                                                                                                    Transit Time</h4>
                                                                                                                <p>{quote.transitTime}+ business days</p>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="qcc-body-list transit">
                                                                                                        <div className="qcc-body-list-heading">
                                                                                                            <h3>Delivery</h3>
                                                                                                        </div>
                                                                                                        <div className="qcc-details">
                                                                                                            <div className="qcc-detail-item">
                                                                                                                <h4 className="qcc-detail-small-heading">
                                                                                                                    Earliest Delivery</h4>
                                                                                                                <h3 className="qcc-detail-result-heading">{formatCustomDate(quote.deliverBy)}</h3>
                                                                                                            </div>
                                                                                                            <div className="qcc-detail-item">
                                                                                                                <h4 className="qcc-detail-small-heading">
                                                                                                                    Latest Delivery</h4>
                                                                                                                <h3 className="qcc-detail-result-heading">{formatCustomDate(quote.deliverByEnd)}</h3>
                                                                                                            </div>
                                                                                                            <div className="qcc-detail-item">
                                                                                                                <h4 className="qcc-detail-small-heading">
                                                                                                                    Where</h4>
                                                                                                                <h3 className="qcc-detail-result-heading">{quote.endZoneDescription}</h3>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* {freightError && freightError.length > 0 &&
                                                                <div className="error_col">
                                                                    <h3>Errors</h3>
                                                                    <ul className="error_cntr">
                                                                        {freightError?.map((item, i) => (
                                                                            <li key={i} className="error-msg mb-3">{item}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            } */}

                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* features */}
                <div className="section-features">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-10 col-lg-10">
                                <div className="section-heading-wrapper">
                                    <h2 className="section-heading">Our Features</h2>
                                    <p className="section-description">We are dedicated to revolutionizing the way you handle cross-border LTL shipments. Our platform offers real-time freight quotes, seamless cross-border solutions, and reliable LTL services. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="features-card-wrapper">
                                    <img className="img-fluid" src={FeatureIcon1} alt="Img" />
                                    <h3>Same day pickups</h3>
                                    <p>Call before 2 pm (origin time) to book.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="features-card-wrapper">
                                    <img className="img-fluid" src={FeatureIcon2} alt="Img" />
                                    <h3>Careful handling/securement</h3>
                                    <p>99.8% of orders deliver without a claim.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="features-card-wrapper">
                                    <img className="img-fluid" src={FeatureIcon3} alt="Img" />
                                    <h3>Expert customs coordination</h3>
                                    <p>Worry-free cross border shipping.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="features-card-wrapper">
                                    <img className="img-fluid" src={FeatureIcon4} alt="Img" />
                                    <h3>Simplified pricing</h3>
                                    <p>Highly competitive per pallet/foot rating.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="features-card-wrapper">
                                    <img className="img-fluid" src={FeatureIcon5} alt="Img" />
                                    <h3>Online tracking & Email alerts</h3>
                                    <p>Get system updates 24 hours a day.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="features-card-wrapper">
                                    <img className="img-fluid" src={FeatureIcon6} alt="Img" />
                                    <h3>Dedicated account managers</h3>
                                    <p>Personalized service from accessible experts.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                <div className="features-card-wrapper">
                                    <img className="img-fluid" src={FeatureIcon7} alt="Img" />
                                    <h3>Company staffed facilities</h3>
                                    <p>Logistics experts who intimately understand each region.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* information */}
                <div className="section-information">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-8 col-lg-8">
                                <div className="section-heading-wrapper">
                                    <h2 className="section-heading text-white">Important information about your LTL shipping quotes</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-4 mb-4">
                                <div className="information-card-wrapper left">
                                    <div className="information-card-item">
                                        <div className="icard-count">
                                            <h4>1</h4>
                                        </div>
                                        <div className="icard-content">
                                            <h3>Shipping Rates</h3>
                                            <p>All LTL freight shipping rates quoted on the livefreightquote.com platform are provided according to the Description of Goods (commodity, dimensions, and weight) and the NMFC Class (for the USA and USA cross-border shipments only) declared by the Shipper and may be subject to additional charges in the case of misdeclaration or misclassification.</p>
                                        </div>
                                    </div>
                                    <div className="information-card-item">
                                        <div className="icard-count">
                                            <h4>3</h4>
                                        </div>
                                        <div className="icard-content">
                                            <h3>Pickup Date and Transit Times</h3>
                                            <p>The pickup date and transit times may vary by carrier and are not guaranteed unless otherwise stated. Transit time starts with the first business day after pickup. The estimated transit time does not include the day of pickup, weekends, or holidays.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 mb-4">
                                <div className="information-card-image">
                                    <img className="img-fluid" src={InfoImg} alt="Img" />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 mb-4">
                                <div className="information-card-wrapper right">
                                    <div className="information-card-item">
                                        <div className="icard-count">
                                            <h4>2</h4>
                                        </div>
                                        <div className="icard-content">
                                            <h3>Truck Waiting Time</h3>
                                            <p>Be mindful of the truck waiting time. Rates quoted include between 15 and 30 minutes of loading and offloading time for LTL shipments, depending on the carrier. There may be additional charges for delays in loading, waiting at facilities for loading or offloading, and/or other unforeseen delays.</p>
                                        </div>
                                    </div>
                                    <div className="information-card-item">
                                        <div className="icard-count">
                                            <h4>4</h4>
                                        </div>
                                        <div className="icard-content">
                                            <h3>Special Handling</h3>
                                            <p>If your load needs Special Handling, please select the special handling option for an accurate quote. Carriers will charge for Value-Added Services if they need to perform additional services not included in the quote.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* transit times */}
                <div className="section-transitime">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-8 col-lg-8">
                                <div className="section-heading-wrapper">
                                    <h2 className="section-heading">Sample Transit Times on Popular LiveFreightQuote.com Lanes</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="transitime-wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Origin Region</th>
                                                <th>Destination Region</th>
                                                <th>Transit Time*</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>New York / Jersey</td>
                                                <td>Vancouver, BC</td>
                                                <td>4 - 7 days</td>
                                            </tr>
                                            <tr>
                                                <td>Seattle, WA</td>
                                                <td>Montreal, QC</td>
                                                <td>4 - 7 days</td>
                                            </tr>
                                            <tr>
                                                <td>Los Angeles, CA</td>
                                                <td>Toronto, ON</td>
                                                <td>3 - 5 days</td>
                                            </tr>
                                            <tr>
                                                <td>Toronto, ON</td>
                                                <td>Edmonton, AB</td>
                                                <td>3 - 5 days</td>
                                            </tr>
                                            <tr>
                                                <td>Dallas, TX</td>
                                                <td>Calgary, AB</td>
                                                <td>3 - 7 days</td>
                                            </tr>
                                            <tr>
                                                <td>Montreal, QC</td>
                                                <td>Vancouver, BC</td>
                                                <td>4 - 7 days</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-10">
                                <div className="transitime-note">
                                    <p>*Transit time are calendar days and apply to major cities in and around the origin/destination cities listed. They are provided as a range (min - max) and will vary depending on the day of week pickup. Add an extra day for locations beyond the regions major cities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modals */}

                {/* modal */}
                <Modal show={show} onHide={handleClose} className="emailInfoModal">
                    <Modal.Header closeButton>
                        <Modal.Title>Rate Quote</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="emailInfoModal-content-wrapper">
                            <p>Your rate request has been received. It is outside of our standard pallet dimensions for auto-rating. Our pricing team is working on your customized rate and you will be emailed shortly from <Link to={'mailto:customerservice@mpsfreight.ca'}>customerservice@mpsfreight.ca</Link>. Please add this email address to your trusted sender list.</p>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showBook} onHide={handleBookClose} className="emailInfoModal">
                    <Modal.Header closeButton>
                        <Modal.Title>Book Quote</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="emailInfoModal-content-wrapper">
                            <p>Quote book request email sent.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </>
    );
};

export default Landing;