import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import LogoWhite from '../images/logo-white.png';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-4">
                        <div className="footer-about-wrapper">
                            <Link to={'../'} className="footer-logo">
                                <img className="img-fluid" src={LogoWhite} alt="Img" />
                            </Link>
                            <h4 className="footer-about-text">People have trusted us for years,<br /> both locally and globally</h4>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-5 mb-4">
                                <div className="footer-links">
                                    <h3 className="footer-heading">Quick Links</h3>
                                    <ul className="footer-link-lists">
                                        <li>
                                            <Link to={'../'}>Home</Link>
                                        </li>
                                        <li>
                                            <Link to={'../services'}>Service</Link>
                                        </li>
                                        <li>
                                            <Link to={'../why-choose-us'}>Why choose us?</Link>
                                        </li>
                                        <li>
                                            <Link to={'../unique-offering'}>Unique offering</Link>
                                        </li>
                                        <li>
                                            <Link to={'../how-it-works'}>How it works</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-7 mb-4">
                                <div className="footer-contact">
                                    <h3 className="footer-heading">Contacts</h3>
                                    <a href="tel:647-424-0711" className="footer-contact-item"><i className="fa fa-phone"></i>647-424-0711</a>
                                    <a href="mailto:livefreightquote@gmail.com" className="footer-contact-item"><i className="fa fa-envelope"></i>livefreightquote@gmail.com</a>
                                </div>
                                <div className="footer-social">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-linkedin"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className="footer-bottom-wrapper">
                            <p>©2024 LiveFreightQuote. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;