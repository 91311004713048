import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import Products from '../images/products.png';
import FeatureIcon from '../images/features.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FieldArray, Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import MapComponent from './MapComponent';
import csa from '../images/csa.png';
import Logo from '../images/main-logo.png';
import WidgetComponent from '../components/WidgetComponent';


const initialShipment = {
    handlingUnits: 1,
    locationType: 'SKD',
    description: '',
    nmfcCode: '',
    dimensions: {
        length: '',
        width: '',
        height: '',
        weight: '',
    },
    stackable: 'Not Stackable',
    temperatureRequirements: 'No Special Handling',
    dangerous: 'Not Dangerous',
};

const initialValues = {
    pickupLocation: '',
    pickupLocationType: '',
    liftgatePickup: false,
    insidePickup: false,
    deliveryLocation: '',
    deliveryLocationType: '',
    appointmentDelivery: false,
    liftgateDelivery: false,
    insideDelivery: false,
    shipments: [
        {
            handlingUnits: 1,
            description: '',
            dimensions: {
                length: '',
                width: '',
                height: '',
                weight: '',
            },
        },
    ],
    contactName: '',
    email: ''
};

const validationSchema = Yup.object({
    pickupLocation: Yup.string().required('Pickup location is required'),
    deliveryLocation: Yup.string().required('Delivery location is required'),
    shipments: Yup.array().of(
        Yup.object().shape({
            description: Yup.string().required('Description is required'),
            handlingUnits: Yup.number().min(1, 'Must be at least 1').required('Required'),
            dimensions: Yup.object().shape({
                length: Yup.number().min(1, 'Must be positive').required('Required'),
                width: Yup.number().min(1, 'Must be positive').required('Required'),
                height: Yup.number().min(1, 'Must be positive').required('Required'),
                weight: Yup.number().min(1, 'Must be positive').required('Required'),
            }),
        })
    ),
    contactName: Yup.string().required('Contact name is required'),
    email: Yup.string().required('Email is required').test('email', 'Invalid email', function (value) {
        if (!value) return false; // Handle empty values separately
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Basic email validation regex
    }),
});

const FreightBook = () => {
    const [startDate, setStartDate] = useState(new Date());
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 30);
    const [shipments, setShipments] = useState([initialShipment]);

    const [fieldErrors, setFieldErrors] = useState({});
    const [error, setError] = useState("");

    const formikRef = useRef(null);


    const handleAddMore = () => {
        // Add a new set of form fields to the array
        setShipments([...shipments, { ...initialShipment }]);
    };

    const [pickupAddress, setPickupAddress] = useState("");
    const [pickupPostalCode, setPickupPostalCode] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [deliveryPostalCode, setDeliveryPostalCode] = useState("");
    const [contactName, setContactName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    const isValidUSZip = (zip) => /^\d{5}(-\d{4})?$/.test(zip);
    const isValidCanadianPostal = (postal) => /^[A-Z]\d[A-Z]\d[A-Z]\d$/.test(postal);
    const isValidZipOrPostal = (code) => {
        const cleanCode = code.replace(/\s/g, '').toUpperCase();
        return isValidUSZip(cleanCode) || isValidCanadianPostal(cleanCode);
    }

    const formatCanadianPostal = (postal) => {
        if (postal.length === 6) {
            return `${postal.slice(0, 3)} ${postal.slice(3)}`;
        }
        return postal;
    };

    const fetchGeocode = async (code) => {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            code
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

        try {
            const response = await fetch(geocodeUrl);
            if (!response.ok) {
                throw new Error('Error fetching geocode data');
            }

            const data = await response.json();

            if (data.status === 'OK' && data.results.length > 0) {
                const result = data.results[0];
                const addressComponents = result.address_components;
                const coordinates = result.geometry.location;
                const locality = extractAddressComponent(addressComponents, 'locality');
                const state = extractAddressComponent(addressComponents, 'administrative_area_level_1');

                return { locality, state, coordinates };
            } else {
                throw new Error('Could not find locality or state');
            }
        } catch (err) {
            throw new Error('Could not find locality or state');
        }
    };

    const extractAddressComponent = (components, type) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component ? component.short_name : null;
    };

    const handlePickupLocation = async (e) => {
        let code = e.target.value.toUpperCase().replace(/\s/g, '');
        if (isValidCanadianPostal(code)) {
            code = formatCanadianPostal(code);
        }
        setPickupPostalCode(code);
        try {
            if (isValidZipOrPostal(code)) {
                console.log("code validated");
                const locationData = await fetchGeocode(code);
                setPickupAddress(`${locationData?.locality} ${locationData?.state}`);
                setShowPickupMap(true);
                setPickupMap(locationData.coordinates);
            } else {
                setPickupAddress('');
                setShowPickupMap(false);
                setError('Invalid U.S. ZIP code or Canadian postal code');
            }
        } catch (error) {
            setPickupAddress('');
            setError(error.message);
        }
    };


    const handleDeliveryLocation = async (e) => {
        let code = e.target.value.toUpperCase().replace(/\s/g, '');
        if (isValidCanadianPostal(code)) {
            code = formatCanadianPostal(code);
        }
        setDeliveryPostalCode(code);
        try {
            if (isValidZipOrPostal(code)) {
                console.log("code validated");
                const locationData = await fetchGeocode(code);
                setDeliveryAddress(`${locationData?.locality} ${locationData?.state}`);
                setDeliveryMap(locationData.coordinates);
                setShowDeliveryMap(true);
            } else {
                setDeliveryAddress('');
                setShowDeliveryMap(false);
                setError('Invalid U.S. ZIP code or Canadian postal code');
            }
        } catch (error) {
            setDeliveryAddress('');
            setError(error.message);
        }
    };
    const resetAll = () => {
        setStartDate(new Date());
        setFieldErrors({});
        setPickupAddress("");
        setPickupPostalCode("");
        setDeliveryAddress("");
        setDeliveryPostalCode("");
        setContactName("");
        setEmail("");
        setError(null);
        setFreightError('');
        setDisplayStyle('none');
        setShowPickupMap(false);
        setShowDeliveryMap(false);

        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: Add smooth scrolling behavior
        });

        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        startAgainClose();
    };

    const [freightError, setFreightError] = useState('');
    const [quoteResponse, setQuoteResponse] = useState({});
    const [displayStyle, setDisplayStyle] = useState('none');

    const onSubmit = async (data) => {
        try {
            const pickupDate = moment(startDate).format('YYYY-MM-DD[T]00:00:00');
            const details = [];
            setFreightError('');
            setIsLoading(true);
            console.log(isLoading);
            data.shipments.forEach((shipment) => {
                details.push({
                    "commodity": "COM",
                    "description": shipment.description,
                    "length": Number(shipment.dimensions.length),
                    "lengthUnits": "IN",
                    "width": Number(shipment.dimensions.width),
                    "widthUnits": "IN",
                    "height": Number(shipment.dimensions.height),
                    "heightUnits": "IN",
                    "weight": Number(shipment.dimensions.weight),
                    "weightUnits": "LB",
                    "pieces": Number(shipment.handlingUnits),
                    "piecesUnits": "SKD",
                    "requestedEquipment": "LTL"
                });
            });
            const requestBody = {
                "contactName": contactName,
                "email": email,
                "orders": [
                    {
                        "details": details,
                        "startZone": pickupPostalCode,
                        "endZone": deliveryPostalCode,
                        "liftgatePickup": data.liftgatePickup,
                        "insidePickup": data.insidePickup,
                        "appointmentDelivery": data.appointmentDelivery,
                        "liftgateDelivery": data.liftgateDelivery,
                        "insideDelivery": data.insideDelivery,
                        "pickupLocationType": data.pickupLocationType,
                        "deliveryLocationType": data.deliveryLocationType,
                        "pickUpBy": pickupDate,
                        "pickUpByEnd": pickupDate,
                        "deliverBy": pickupDate,
                        "deliverByEnd": pickupDate
                    }

                ]
            };
            const headers = {
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/quotes`, requestBody, {
                headers: headers
            });
            console.log('Response:', response.data);
            setIsLoading(false);
            console.log(isLoading);
            if (response.data.quotes && response.data.quotes.length > 0) {
                setQuoteResponse(response.data);
                setDisplayStyle('block');
            } else {
                if (response.data.errors && response.data.errors.length > 0) {
                    setDisplayStyle('none');
                    setFreightError(response.data.errors);
                } else {
                    setDisplayStyle('none');
                    handleShow();
                }
            }
        } catch (e) {
            console.log(e);
            setDisplayStyle('none');
            //setFreightError(e.response.data.errors);
            setIsLoading(false);
        }
    };

    const sendBookNotification = async (index) => {
        try {
            const requestBody = {
                "contactName": contactName,
                "email": email,
                "quote": quoteResponse.quotes[index]
            };
            const headers = {
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/book/quote`, requestBody, {
                headers: headers
            });
            if (response.data) {
                setShowBook(true);
                console.log('Message:', response.data.message);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [show, setShow] = useState(false);
    const [showBook, setShowBook] = useState(false);
    const [startAgainModal, setShowStart] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const startAgainClose = () => setShowStart(false);
    const startAgainShow = () => setShowStart(true);

    const handleBookClose = () => setShowBook(false);

    const [pickupMap, setPickupMap] = useState({
        lat: 40.712776,
        lng: -74.005974
    });
    const [showPickupMap, setShowPickupMap] = useState(false);
    const [deliveryMap, setDeliveryMap] = useState({
        lat: 40.712776,
        lng: -74.005974
    });
    const [showDeliveryMap, setShowDeliveryMap] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState([]);
    const handleClick = (index) => {
        //setIsActive(!isActive); // Toggle the state
        if (activeIndex.includes(index)) {
            let remove = index;
            const updatedItems = activeIndex.filter((item) => item !== remove);
            setActiveIndex(updatedItems);
        }
        if (!activeIndex.includes(index)) {
            setActiveIndex([...activeIndex, index]);
        }
    };

    const formatCustomDate = (inputDate) => {
        const dateObj = moment(inputDate);
        const dayOfMonth = dateObj.date();
        const monthName = dateObj.format("MMMM");
        const year = dateObj.year();

        let daySuffix;
        if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
            daySuffix = "st";
        } else if (dayOfMonth === 2 || dayOfMonth === 22) {
            daySuffix = "nd";
        } else if (dayOfMonth === 3 || dayOfMonth === 23) {
            daySuffix = "rd";
        } else {
            daySuffix = "th";
        }

        return `${dateObj.format("ddd")} ${dateObj.format("DD").replace(/^0/, "")}${daySuffix} ${monthName} ${year}`;
    };

    const markupRate = (quote) => {
        let rate;
        if (Number(quote.totalCharges) < 300 || quote.details.length <= 2) {
            rate = (Number(quote.totalCharges) * 1.33).toFixed(2);
        } else if (quote.details.length > 2) {
            rate = (Number(quote.totalCharges) * 1.22).toFixed(2);
        }
        return rate;
    };

    return (
        <div className="main-container">
            <div className="page-header-container">
                <div className="top-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="topheader-wrapper">
                                    <Link to={'../'}><img className="pageBrand-img" src={Logo} alt="Img" /></Link>
                                    <div className="topheader-contact">
                                        <a href="tel:866-200-9989"><i className="fa fa-phone"></i>866-200-9989</a>
                                        <a href="mailto:livefreightquote@gmail.com"><i className="fa fa-envelope"></i>livefreightquote@gmail.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-header"></div>
            </div>
            <div className="main-form-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="form-content-wrapper">
                                <div id="reviews" style={{ padding: '4rem 3rem 0rem 3rem' }}>
                                    <WidgetComponent />
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    innerRef={formikRef}
                                    onSubmit={(values) => {
                                        try {
                                            console.log(values);
                                            onSubmit(values);
                                        }
                                        catch (error) {
                                            console.log(error);

                                            setIsLoading(false);
                                        }
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, handleBlur }) => (
                                        <Form id="quote-form">
                                            <div className="form-info-banner-wrapper">
                                                <div className="form-info-banner">
                                                    <div className="row align-items-center justify-content-center">
                                                        <div className="col-12 col-md-10 col-lg-10">
                                                            <img className="img-fluid packageImg" src={Products} alt="Img" />
                                                            <h1 className="info-banner-heading">Get Instant Quotes</h1>
                                                            <h4 className="info-banner-description">Enter your shipment details to get accurate quotes from dozens of the best carriers in North America.</h4>
                                                            <p className="info-banner-small-description">⚠️ FreightQuote does not handle small packages, parcels, vehicles, or home moves.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Contact Details */}
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    <div className="step-contact-details-wrapper">
                                                        <div className="step-count">
                                                            <h2>1</h2>
                                                        </div>
                                                        <div className="step-info">
                                                            <h3>Contact Details</h3>
                                                            <p>Please be accurate with the details you enter below</p>
                                                        </div>
                                                        <div className="form-wrapper">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-input-group">
                                                                        <label className="form-label" htmlFor="label_name" >Contact Name</label>
                                                                        <Field name='contactName' className="form-input" type="text" placeholder="Enter contact name" value={contactName} onChange={(e) => {
                                                                            setContactName(e.target.value);
                                                                            handleChange(e);
                                                                        }
                                                                        }
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        {touched.contactName && errors.contactName && <span className="error-msg">{errors.contactName}</span>}                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-input-group">
                                                                        <label className="form-label" htmlFor="label_name" >Email</label>
                                                                        <Field name='email' className="form-input" type="text" placeholder="Enter email" value={email} onChange={(e) => {
                                                                            setEmail(e.target.value);
                                                                            handleChange(e);
                                                                        }
                                                                        }
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        {touched.email && errors.email && <span className="error-msg">{errors.email}</span>}                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Pick Date */}
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    <div className="step-pickup-wrapper date">
                                                        <div className="step-count">
                                                            <h2>2</h2>
                                                        </div>
                                                        <div className="step-info">
                                                            <h3>Pickup Date</h3>
                                                            <p>A pickup can be booked within one month. While most pickups happen on the requested date, this is not guaranteed. Pickup dates can be changed before and after booking.</p>
                                                        </div>
                                                        <div className="row align-items-center justify-content-center">
                                                            <div className="col-12 col-md-10 col-lg-10">
                                                                <div className="datepicker">
                                                                    <DatePicker
                                                                        inline
                                                                        selected={startDate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                        monthsShown={2}
                                                                        minDate={today}
                                                                        maxDate={maxDate}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Pickup Location */}
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    <div className="step-pickup-wrapper location">
                                                        <div className="step-count">
                                                            <h2>3</h2>
                                                        </div>
                                                        <div className="step-info">
                                                            <h3>Pickup Location</h3>
                                                            <p>Enter the details of your pickup location</p>
                                                        </div>

                                                        <div className="form-wrapper">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-input-group">
                                                                        <label className="form-label" htmlFor="label_name" >Pickup Postal Code / Zip Code</label>
                                                                        <div className="postalCode-city-inputs">
                                                                            <Field name='pickupLocation' className="form-input-half" type="text" placeholder="Enter postal code" value={pickupPostalCode} onChange={(e) => {
                                                                                handleChange(e);
                                                                                handlePickupLocation(e);
                                                                            }
                                                                            }
                                                                                onBlur={handleBlur}
                                                                            />
                                                                            <input className="form-input-half" type="text" placeholder="" value={pickupAddress} readOnly />
                                                                        </div>
                                                                        {touched.pickupLocation && errors.pickupLocation && (
                                                                            <span className="error-msg">{errors.pickupLocation}</span>
                                                                        )}
                                                                        <div className="inputMap">
                                                                            <MapComponent lat={pickupMap.lat} lng={pickupMap.lng} location={pickupAddress} visible={showPickupMap} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-input-group mb-0">
                                                                        <label className="form-label" htmlFor="label_name" >Pickup Location Type<i className="fa fa-question-circle-o picklocTypeHover"></i>
                                                                            <div className="info-box picklocTypeDisplay">
                                                                                <h3>What type of location is the pickup address?</h3>
                                                                                <p>Make sure you enter the correct location type, as it affects the quote.</p>
                                                                                <p>If at pickup, the location type is different than the type you specified, you may incur an additional charge.</p>
                                                                            </div>
                                                                        </label>

                                                                        <Field as="select" name="pickupLocationType" className="form-input">
                                                                            <option value="Warehouse">Warehouse</option>
                                                                            <option value="Grocery Warehouse">Grocery Warehouse</option>
                                                                            <option value="Residential">Residential</option>
                                                                            <option value="Construction Site">Construction Site</option>
                                                                        </Field>

                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-input-group">
                                                                        <label className="form-label" htmlFor="label_name" >Logistics</label>
                                                                        <div className="form-checkbox-list">
                                                                            <div className="form-checkbox-item">
                                                                                <label className="form-checkbox-wrapper">
                                                                                    <div className="form-checkbox-info">
                                                                                        <h3>Lift gate required on truck<i className="fa fa-question-circle-o truckHover"></i>
                                                                                            <div className="info-box truckDisplay">
                                                                                                <h3>Lift gate required at pickup</h3>
                                                                                                <p>Check this box if you are unable to load the freight onto the truck at your pickup location. For example, if you don't have a dock, loading bay, or a forklift.</p>
                                                                                            </div>
                                                                                        </h3>
                                                                                        <p>The pickup location does not have a loading dock</p>
                                                                                    </div>
                                                                                    <Field type="checkbox" name="liftgatePickup" />
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-checkbox-item">
                                                                                <label className="form-checkbox-wrapper">
                                                                                    <div className="form-checkbox-info">
                                                                                        <h3>Pickup inside the location<i className="fa fa-question-circle-o insideHover"></i>
                                                                                            <div className="info-box insideDisplay">
                                                                                                <h3>Inside pickup</h3>
                                                                                                <p>The driver is required to go beyond the immediate area of the receiving door to pickup the freight.</p>
                                                                                            </div>
                                                                                        </h3>
                                                                                        <p>The freight needs to be brought from inside the building</p>
                                                                                    </div>
                                                                                    <Field type="checkbox" name="insidePickup" />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Delivery Location */}
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    <div className="step-pickup-wrapper deliverylocation">
                                                        <div className="step-count">
                                                            <h2>4</h2>
                                                        </div>
                                                        <div className="step-info">
                                                            <h3>Delivery Location</h3>
                                                            <p>Enter the details of your delivery location</p>
                                                        </div>

                                                        <div className="form-wrapper">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-input-group">
                                                                        <label className="form-label" htmlFor="label_name" >Delivery Postal Code / Zip Code</label>
                                                                        <div className="postalCode-city-inputs">
                                                                            <Field name="deliveryLocation" className="form-input-half" type="text" placeholder="Enter postal code" value={deliveryPostalCode} onChange={(e) => {
                                                                                handleChange(e);
                                                                                handleDeliveryLocation(e);
                                                                            }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                            <input className="form-input-half" type="text" placeholder="" value={deliveryAddress} readOnly />
                                                                        </div>
                                                                        {touched.deliveryLocation && errors.deliveryLocation && (
                                                                            <span className="error-msg">{errors.deliveryLocation}</span>
                                                                        )}
                                                                        <input
                                                                            type="hidden"
                                                                            placeholder="Delivery Postal Code"
                                                                            value={deliveryPostalCode}
                                                                            readOnly
                                                                        />
                                                                        {fieldErrors.delivery_code && <span className="error-msg">{fieldErrors.delivery_code}</span>}
                                                                        <div className="inputMap">
                                                                            <MapComponent lat={deliveryMap.lat} lng={deliveryMap.lng} location={deliveryAddress} visible={showDeliveryMap} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-input-group mb-0">
                                                                        <label className="form-label" htmlFor="label_name" >Delivery Location Type
                                                                            <i className="fa fa-question-circle-o deliveryTypeHover"></i>
                                                                            <div className="info-box deliveryTypeDisplay">
                                                                                <h3>What type of location is the delivery address?</h3>
                                                                                <p>Make sure you enter the correct location type, as it affects the quote.</p>
                                                                                <p>If at delivery, the location type is different than the type you specified, you may incur an additional charge.</p>
                                                                            </div>
                                                                        </label>
                                                                        <Field as="select" name="deliveryLocationType" className="form-input">
                                                                            <option value="Warehouse">Warehouse</option>
                                                                            <option value="Grocery Warehouse">Grocery Warehouse</option>
                                                                            <option value="Residential">Residential</option>
                                                                            <option value="Construction Site">Construction Site</option>
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-input-group">
                                                                        <label className="form-label" htmlFor="label_name" >Scheduling</label>
                                                                        <div className="form-checkbox-list">
                                                                            <div className="form-checkbox-item">
                                                                                <label className="form-checkbox-wrapper">
                                                                                    <div className="form-checkbox-info">
                                                                                        <h3>Delivery appointment required
                                                                                            <i className="fa fa-question-circle-o deliveryAppiontHover"></i>
                                                                                            <div className="info-box deliveryAppiontDisplay">
                                                                                                <h3>Delivery appointment required at delivery</h3>
                                                                                                <p>Check this box if you need the carrier to deliver within a specific time window or two hours or less. They will contact the delivery contact to confirm the time.</p>
                                                                                            </div>
                                                                                        </h3>
                                                                                        <p>The location requires the carrier to make a delivery appointment</p>
                                                                                    </div>
                                                                                    <Field type="checkbox" name="appointmentDelivery" />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-input-group mb-0">
                                                                        <label className="form-label" htmlFor="label_name" >Logistics</label>
                                                                        <div className="form-checkbox-list">
                                                                            <div className="form-checkbox-item">
                                                                                <label className="form-checkbox-wrapper">
                                                                                    <div className="form-checkbox-info">
                                                                                        <h3>Lift gate required on truck
                                                                                            <i className="fa fa-question-circle-o deliveryTruckHover"></i>
                                                                                            <div className="info-box deliveryTruckDisplay">
                                                                                                <h3>Lift gate required at delivery</h3>
                                                                                                <p>Check this box if you are unable to unload the freight off the truck at your delivery location. For example, if you don't have a dock, loading bay, or a forklift.</p>
                                                                                            </div>
                                                                                        </h3>
                                                                                        <p>The delivery location does not have a loading dock</p>
                                                                                    </div>
                                                                                    <Field type="checkbox" name="liftgateDelivery" />
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-checkbox-item">
                                                                                <label className="form-checkbox-wrapper">
                                                                                    <div className="form-checkbox-info">
                                                                                        <h3>Delivery inside the location
                                                                                            <i className="fa fa-question-circle-o deliveryInsideHover"></i>
                                                                                            <div className="info-box deliveryInsideDisplay">
                                                                                                <h3>Inside delivery</h3>
                                                                                                <p>The driver is required to go beyond the immediate area of the receiving door to deliver the freight.</p>
                                                                                            </div>
                                                                                        </h3>
                                                                                        <p>The freight needs to be brought inside the building</p>
                                                                                    </div>
                                                                                    <Field type="checkbox" name="insideDelivery" />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Shipment Contents */}
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    <div className="step-pickup-wrapper shipping-content">
                                                        <div className="step-count">
                                                            <h2>5</h2>
                                                        </div>
                                                        <div className="step-info">
                                                            <h3>Shipment Contents</h3>
                                                            <p>Please be accurate with the details you enter below</p>
                                                        </div>
                                                        <div className="form-wrapper pb-4">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div className="shipment-ship-wrapper">
                                                                        <p className="shipment-ship-heading">What would you like to ship?</p>
                                                                        <button type="text" className="shipment-ship-link">Bearings</button>
                                                                        <button type="text" className="shipment-ship-link">Something else</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FieldArray name="shipments">
                                                            {({ push, remove, form }) => (
                                                                <>
                                                                    {values.shipments.map((shipment, index) => (
                                                                        //{shipments.map((shipment, index) => (
                                                                        <div id="cloned-contents" key={index}>
                                                                            <div id="ship-content" className="form-wrapper">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-md-6 col-lg-6">
                                                                                        <div className="form-input-group">
                                                                                            <label className="form-label" htmlFor="label_name" >Handling Units
                                                                                                <i className="fa fa-question-circle-o handUnitHover"></i>
                                                                                                <div className="info-box handUnitDisplay">
                                                                                                    <h3>Handling Units</h3>
                                                                                                    <p>Select how many of the same sized handling units you have.</p>
                                                                                                </div>
                                                                                            </label>
                                                                                            <div className="unit-selection">
                                                                                                <div className="unit-increase-decrease">
                                                                                                    <Field
                                                                                                        type="number"
                                                                                                        className="form-input"
                                                                                                        name={`shipments[${index}].handlingUnits`}
                                                                                                        readOnly />
                                                                                                    {touched.shipments &&
                                                                                                        touched.shipments[index] &&
                                                                                                        errors.shipments &&
                                                                                                        errors.shipments[index]?.handlingUnits && (
                                                                                                            <span className="error-msg">
                                                                                                                {errors.shipments[index]?.handlingUnits}
                                                                                                            </span>
                                                                                                        )}
                                                                                                    {/* <input
                                                                                    className="form-input"
                                                                                    type="number"
                                                                                    min="1"
                                                                                    defaultValue="1"
                                                                                    required
                                                                                    value={shipment.handlingUnits}
                                                                                    onChange={(e) => handleChange(index, 'handlingUnits', e.target.value)}
                                                                                /> */}
                                                                                                </div>
                                                                                                <Field as="select" className="form-input" value={shipment.locationType}>
                                                                                                    <option value="SKD">Pallet</option>
                                                                                                    {/* <option value="SKD">Skid</option> */}
                                                                                                </Field>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-input-group mb-0">
                                                                                            <label className="form-label" htmlFor="label_name" >What is on this pallet?</label>

                                                                                            <div className="info-with-input">
                                                                                                <span>Description
                                                                                                    <i className="fa fa-question-circle-o palletDescripHover"></i>
                                                                                                    <div className="info-box palletDescripDisplay">
                                                                                                        <h3>Pallet Content</h3>
                                                                                                        <p>Enter a accurate description of what is on each pallet.</p>
                                                                                                        <p>A vague description can cause delays if your shipment is crossing the border.</p>
                                                                                                        <p>Each carrier maintains their own list of prohibited items. If you have any doubt if something is suitable to ship please contact us, and we can advise.</p>
                                                                                                    </div>
                                                                                                </span>
                                                                                                {/* <Field name={`shipments[${index}].description`}  className="form-input" type="text" placeholder="Enter description" value={shipment.description} onChange={(e) => {
                                                                                handleChange(index, 'description', e.target.value);
                                                                            }}
                                                                            /> */}
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    className="form-input"
                                                                                                    name={`shipments[${index}].description`}
                                                                                                    placeholder="Enter description"
                                                                                                />
                                                                                                {touched.shipments &&
                                                                                                    touched.shipments[index] &&
                                                                                                    errors.shipments &&
                                                                                                    errors.shipments[index]?.description && (
                                                                                                        <span className="error-msg">
                                                                                                            {errors.shipments[index]?.description}
                                                                                                        </span>
                                                                                                    )}
                                                                                            </div>

                                                                                            <div className="info-with-input">
                                                                                                <span>NMFC Code
                                                                                                    <i className="fa fa-question-circle-o nmfcHover"></i>
                                                                                                    <div className="info-box nmfcDisplay">
                                                                                                        <h3>(Optional) NMFC Code</h3>
                                                                                                        <p>If you have one, enter a 5-6 digit NMFC code here to help carriers under the type of freight you are shipping more easily. With an NMFC code provided some carriers will be able to offer cheaper quotes.</p>
                                                                                                        <p> Using LiveFreightQuote, you don't need to worry about the freight class (eg. 50, 70, 110, etc), since we calculate it for you.</p>
                                                                                                        <p>Our team can help you figure out the correct NMFC code for your shipment.</p>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <input className="form-input" type="text" placeholder="If known, enter the NMFC Code" value={shipment.nmfcCode} onChange={(e) => handleChange(index, 'nmfcCode', e.target.value)} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-6 col-lg-6">
                                                                                        <div className="form-input-group">
                                                                                            <label className="form-label" htmlFor="label_name" >What are the dimensions for each pallet?
                                                                                                <i className="fa fa-question-circle-o dimensionsHover"></i>
                                                                                                <div className="info-box dimensionsDisplay">
                                                                                                    <h3>Dimensions</h3>
                                                                                                    <p>Correct dimensions ensure you get accurate quotes from carriers.</p>
                                                                                                    <p>If you are shipping multiple identical pallets , this should be the dimensions and weight of each single pallet.</p>
                                                                                                    <p>Please add an additional row if you are shipping multiple pallets with different products, dimensions or weights.</p>
                                                                                                    <p>Maximum handling unit dimensions: 50ft long x 96" wide x 102" high</p>
                                                                                                </div>
                                                                                            </label>

                                                                                            <div className="row">
                                                                                                <div className="col-12 col-md-6 col-lg-3">
                                                                                                    <div className="info-with-input">
                                                                                                        <p>Length (in)</p>
                                                                                                        {/* <input className="form-input box-dimension" type="text" value={shipment.dimensions.length} onChange={(e) => handleDimensionChange(index, 'length', e.target.value)} /> */}
                                                                                                        <Field
                                                                                                            type="number"
                                                                                                            className="form-input box-dimension"
                                                                                                            name={`shipments[${index}].dimensions.length`}
                                                                                                            min="1" />
                                                                                                        {touched.shipments &&
                                                                                                            touched.shipments[index] &&
                                                                                                            errors.shipments &&
                                                                                                            errors.shipments[index]?.dimensions?.length && (
                                                                                                                <span className="error-msg">
                                                                                                                    {errors.shipments[index]?.dimensions?.length}
                                                                                                                </span>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-3">
                                                                                                    <div className="info-with-input">
                                                                                                        <p>Width (in)</p>
                                                                                                        {/* <input className="form-input box-dimension" type="text" value={shipment.dimensions.width} onChange={(e) => handleDimensionChange(index, 'width', e.target.value)} /> */}
                                                                                                        <Field
                                                                                                            type="number"
                                                                                                            className="form-input box-dimension"
                                                                                                            name={`shipments[${index}].dimensions.width`}
                                                                                                            min="1" />
                                                                                                        {touched.shipments &&
                                                                                                            touched.shipments[index] &&
                                                                                                            errors.shipments &&
                                                                                                            errors.shipments[index]?.dimensions?.width && (
                                                                                                                <span className="error-msg">
                                                                                                                    {errors.shipments[index]?.dimensions?.width}
                                                                                                                </span>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-3">
                                                                                                    <div className="info-with-input">
                                                                                                        <p>Height (in)</p>
                                                                                                        {/* <input className="form-input box-dimension" type="text" value={shipment.dimensions.height} onChange={(e) => handleDimensionChange(index, 'height', e.target.value)} /> */}
                                                                                                        <Field
                                                                                                            type="number"
                                                                                                            className="form-input box-dimension"
                                                                                                            name={`shipments[${index}].dimensions.height`}
                                                                                                            min="1" />
                                                                                                        {touched.shipments &&
                                                                                                            touched.shipments[index] &&
                                                                                                            errors.shipments &&
                                                                                                            errors.shipments[index]?.dimensions?.height && (
                                                                                                                <span className="error-msg">
                                                                                                                    {errors.shipments[index]?.dimensions?.height}
                                                                                                                </span>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-3">
                                                                                                    <div className="info-with-input">
                                                                                                        <p>Weight (lb)</p>
                                                                                                        {/* <input className="form-input box-dimension" type="text" value={shipment.dimensions.weight} onChange={(e) => handleDimensionChange(index, 'weight', e.target.value)} /> */}
                                                                                                        <Field
                                                                                                            type="number"
                                                                                                            className="form-input box-dimension"
                                                                                                            name={`shipments[${index}].dimensions.weight`}
                                                                                                            min="1" />
                                                                                                        {touched.shipments &&
                                                                                                            touched.shipments[index] &&
                                                                                                            errors.shipments &&
                                                                                                            errors.shipments[index]?.dimensions?.weight && (
                                                                                                                <span className="error-msg">
                                                                                                                    {errors.shipments[index]?.dimensions?.weight}
                                                                                                                </span>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-input-group mb-0">
                                                                                            <label className="form-label" htmlFor="label_name" >Special Handling</label>

                                                                                            <div className="info-with-input">
                                                                                                <span>Is Stackable?
                                                                                                    <i className="fa fa-question-circle-o stackHover"></i>
                                                                                                    <div className="info-box stackDisplay">
                                                                                                        <h3>Stackable</h3>
                                                                                                        <p>Is your freight stackable? Allowing freight to be stacked can greatly increase the risk of damages, however in some circumstances it might lead to lower prices.</p>
                                                                                                        <p>If in doubt, we recommend marking it as not stackable.</p>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <select className="form-input" value={shipment.stackable}
                                                                                                    onChange={(e) => handleChange(index, 'stackable', e.target.value)}>
                                                                                                    <option>Not Stackable</option>
                                                                                                    <option>Pallet is stackable</option>
                                                                                                </select>
                                                                                            </div>

                                                                                            <div className="info-with-input">
                                                                                                <span>Temperature Requirements
                                                                                                    <i className="fa fa-question-circle-o tempHover"></i>
                                                                                                    <div className="info-box tempDisplay">
                                                                                                        <h3>Stackable</h3>
                                                                                                        <p>If your shipment requires protection from freezing, or refridgerated or frozen handling you can specify it here.</p>
                                                                                                        <p>Unfortunatley we don't currently support refridgerated or frozen shipments</p>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <select className="form-input" value={shipment.temperatureRequirements}
                                                                                                    onChange={(e) => handleChange(index, 'temperatureRequirements', e.target.value)}>
                                                                                                    <option>No Special Handling</option>
                                                                                                    <option>Protect From Freezing</option>
                                                                                                    <option>Keep Refrigerated</option>
                                                                                                    <option>Keep Frozen</option>
                                                                                                </select>
                                                                                            </div>

                                                                                            <div className="info-with-input">
                                                                                                <span>Dangerous?
                                                                                                    <i className="fa fa-question-circle-o dangerHover"></i>
                                                                                                    <div className="info-box dangerDisplay">
                                                                                                        <h3>Dangerous</h3>
                                                                                                        <p>If your freight is dangerous/hazardous the carrier needs to know about it. Please enter details.</p>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <select className="form-input" value={shipment.dangerous}
                                                                                                    onChange={(e) => handleChange(index, 'dangerous', e.target.value)}>
                                                                                                    <option>Not Dangerous</option>
                                                                                                    <option>Dangerous</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-12">
                                                                                        {index > 0 && (<button type="button" className="remove-btn" onClick={() => remove(index)}><i className="fa fa-trash"></i>Remove</button>)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    )}

                                                                    <div className="add-more-wrapper dropdown">
                                                                        <button type="button" className="addmore-btn" onClick={() => {
                                                                            push(initialValues.shipments[0]);
                                                                            handleAddMore();
                                                                        }} data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-plus-circle"></i>Add more to this shipment</button>

                                                                        <div className="dropdown-menu">
                                                                            <div className="shipment-ship-wrapper">
                                                                                <p className="shipment-ship-heading">What would you like to ship?</p>
                                                                                <button type="text" className="shipment-ship-link">Bearings</button>
                                                                                <button type="text" className="shipment-ship-link">Something else</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Quote Result */}
                                            <div className="quote-result-container" style={{ display: displayStyle }}>
                                                <div className="row align-items-center justify-content-center">
                                                    <div className="col-12 col-md-11 col-lg-11">
                                                        <div className="quotes-heading">
                                                            <h3>Quotes</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-11 col-lg-11">
                                                        {quoteResponse && quoteResponse.quotes && (
                                                            <div className="quote-list">
                                                                {/* <!-- quote item 1 --> */}
                                                                {quoteResponse.quotes.map((quote, index) => (
                                                                    <div className="quote-item" key={index}>
                                                                        <div className="quote-main-content">
                                                                            {/* <div className="quote-item-logo">
                                                                        <img className="img-fluid" src={csa}
                                                                            alt="Img" />
                                                                    </div> */}
                                                                            <div><p>&nbsp;</p></div>
                                                                            {!activeIndex.includes(index) ? (
                                                                                <div className={`quote-item-content`}>
                                                                                    {/* <div className="quote-item-service-level">
                                                                            <p className="quote-item-heading">Service Level</p>
                                                                            <h2 className="quote-item-result">{''}</h2>
                                                                        </div> */}
                                                                                    <div className="quote-item-delivery">
                                                                                        <p className="quote-item-heading">Earliest Delivery</p>
                                                                                        <h2 className="quote-item-result">{formatCustomDate(quote.deliveryDate)}</h2>
                                                                                    </div>
                                                                                    <div className="quote-item-transit">
                                                                                        <p className="quote-item-heading">Transit</p>
                                                                                        <h2 className="quote-item-result">
                                                                                            {quote.transitTime}
                                                                                            <span className="plus">+</span>
                                                                                            <span className="days">Business<br />Days</span>
                                                                                        </h2>
                                                                                    </div>
                                                                                    <div className="quote-item-price">
                                                                                        <h1><span className="price-dollar">$</span>{markupRate(quote)}<span
                                                                                            className="price-currency">{quote.currencyCode}</span></h1>
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}
                                                                            <div className="quote-drop-wrapper">
                                                                                {/* <!-- collapse --> */}
                                                                                <button onClick={() => handleClick(index)} className="quoteDownbtn" type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target={`#collapseExample-${index}`} aria-expanded="false"
                                                                                    aria-controls={`collapseExample-${index}`}></button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="quote-item-dropcontent">
                                                                            <div className="collapse" id={`collapseExample-${index}`}>
                                                                                <div className="quote-collapse-content">
                                                                                    <div className="qcc-head">
                                                                                        <div className="qcc-head-left">
                                                                                            <h2>Transit: {quote.transitTime}+ Business Days</h2>
                                                                                            <p>Earliest Delivery: {formatCustomDate(quote.deliveryDate)}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="qcc-head-right">
                                                                                            <h2><span className="qcc-dollar">$</span>{markupRate(quote)}<span
                                                                                                className="qcc-currency">{quote.currencyCode}</span></h2>
                                                                                            <p>Inclusive of all taxes</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                        <Button variant="" onClick={() => sendBookNotification(index)} className="theme-btn btn-main mt-2">Book</Button>
                                                                                    </div>
                                                                                    <div className="qcc-body">
                                                                                        <div className="qcc-body-list pickup">
                                                                                            <div className="qcc-body-list-heading">
                                                                                                <h3>Pickup</h3>
                                                                                            </div>
                                                                                            <div className="qcc-details">
                                                                                                <div className="qcc-detail-item">
                                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                                        When</h4>
                                                                                                    <h3 className="qcc-detail-result-heading">
                                                                                                        {formatCustomDate(quote.pickUpBy)}</h3>
                                                                                                    <p>Pickup window: {moment(quote.pickUpBy).format("ha")}-{moment(quote.pickUpByEnd).format("ha")}</p>
                                                                                                </div>
                                                                                                <div className="qcc-detail-item">
                                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                                        Where</h4>
                                                                                                    <h3 className="qcc-detail-result-heading">
                                                                                                        {quote.startZoneDescription}</h3>
                                                                                                    {/* <p>Warehouse</p> */}
                                                                                                </div>
                                                                                                <div className="qcc-detail-item">
                                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                                        Payload</h4>
                                                                                                    <h3 className="qcc-detail-result-heading">
                                                                                                        Total Weight: {quote.totalWeight}lb</h3>
                                                                                                    {quote.details.map((detail, index) => (
                                                                                                        <>
                                                                                                            <p>{detail.pieces} X {detail.piecesUnits} {detail.description}</p>
                                                                                                            <p>{detail.length}" X {detail.width}" x {detail.height}"</p>
                                                                                                        </>
                                                                                                    ))}
                                                                                                </div>
                                                                                                {/* <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Special Services</h4>
                                                                                            <ul>
                                                                                                <li>None Selected</li>
                                                                                            </ul>
                                                                                        </div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="qcc-body-list transit">
                                                                                            <div className="qcc-body-list-heading">
                                                                                                <h3>Transit</h3>
                                                                                            </div>
                                                                                            <div className="qcc-details">
                                                                                                <div className="qcc-detail-item">
                                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                                        Carrier</h4>
                                                                                                    <p>{quote.carrierName}</p>
                                                                                                </div>
                                                                                                {/* <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Service Type</h4>
                                                                                            <p>{''}</p>
                                                                                        </div> */}
                                                                                                <div className="qcc-detail-item">
                                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                                        Transit Time</h4>
                                                                                                    <p>{quote.transitTime}+ business days</p>
                                                                                                </div>
                                                                                                {/* <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Maximum Liability</h4>
                                                                                            <p>$2 / pound</p>
                                                                                        </div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="qcc-body-list transit">
                                                                                            <div className="qcc-body-list-heading">
                                                                                                <h3>Delivery</h3>
                                                                                            </div>
                                                                                            <div className="qcc-details">
                                                                                                <div className="qcc-detail-item">
                                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                                        Earliest Delivery</h4>
                                                                                                    <h3 className="qcc-detail-result-heading">{formatCustomDate(quote.deliverBy)}</h3>
                                                                                                </div>
                                                                                                <div className="qcc-detail-item">
                                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                                        Latest Delivery</h4>
                                                                                                    <h3 className="qcc-detail-result-heading">{formatCustomDate(quote.deliverByEnd)}</h3>
                                                                                                </div>
                                                                                                <div className="qcc-detail-item">
                                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                                        Where</h4>
                                                                                                    <h3 className="qcc-detail-result-heading">{quote.endZoneDescription}</h3>
                                                                                                    {/* <p>Warehouse</p> */}
                                                                                                </div>
                                                                                                {/* <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Special Services</h4>
                                                                                            <ul>
                                                                                                <li>None Selected</li>
                                                                                            </ul>
                                                                                        </div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {/* form submit */}
                                            <div className="get-btns-wrapper">
                                                {freightError !== "" &&
                                                    <div className="error-msg">
                                                        {freightError}
                                                    </div>
                                                }
                                                <div className="gtnBlock">
                                                    <button className='theme-btn btn-main' type='submit' disabled={isLoading}>
                                                        {isLoading ? (
                                                            <>
                                                                <span>Submitting...</span>
                                                                <div className="buttonSpinnerBlock">
                                                                    <span className="buttonSpinner"></span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                Get instant quotes from carriers <i className="fa fa-long-arrow-right"></i>
                                                            </>
                                                        )}
                                                    </button>
                                                    <Button variant="" onClick={startAgainShow} className="start-again-btn">Start Again</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- our features --> */}
            <div className="features-container">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Our Features</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-12 col-md-6 col-lg-4 col-item-mb">
                            <div className="feature-card-wrapper">
                                <div className="feature-icon one">
                                    <img className="img-fluid" src={FeatureIcon} alt="Img" />
                                </div>
                                <h3>Same day pickups</h3>
                                <p>Call before 2 pm (origin time) to book.</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 col-item-mb">
                            <div className="feature-card-wrapper">
                                <div className="feature-icon three">
                                    <img className="img-fluid" src={FeatureIcon} alt="Img" />
                                </div>
                                <h3>Careful handling/securement</h3>
                                <p>99.8% of orders deliver without a claim.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-item-mb">
                            <div className="feature-card-wrapper">
                                <div className="feature-icon four">
                                    <img className="img-fluid" src={FeatureIcon} alt="Img" />
                                </div>
                                <h3>Expert customs coordination</h3>
                                <p>Worry-free cross border shipping.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-item-mb">
                            <div className="feature-card-wrapper">
                                <div className="feature-icon five">
                                    <img className="img-fluid" src={FeatureIcon} alt="Img" />
                                </div>
                                <h3>Simplified pricing</h3>
                                <p>Highly competitive per pallet/foot rating.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-item-mb">
                            <div className="feature-card-wrapper">
                                <div className="feature-icon six">
                                    <img className="img-fluid" src={FeatureIcon} alt="Img" />
                                </div>
                                <h3>Online tracking & Email alerts</h3>
                                <p>Get system updates 24 hours a day.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-item-mb">
                            <div className="feature-card-wrapper">
                                <div className="feature-icon seven">
                                    <img className="img-fluid" src={FeatureIcon} alt="Img" />
                                </div>
                                <h3>Dedicated account managers</h3>
                                <p>Personalized service from accessible experts.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-item-mb">
                            <div className="feature-card-wrapper">
                                <div className="feature-icon eight">
                                    <img className="img-fluid" src={FeatureIcon} alt="Img" />
                                </div>
                                <h3>Company staffed facilities</h3>
                                <p>Logistics experts who intimately understand each region.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Important information --> */}
            <div className="important-info-container">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading text-white">Important information about your LTL shipping quotes</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="info-content-wrapper">
                                <div className="info-content-count">
                                    <h3>1</h3>
                                </div>
                                <div className="info-contents">
                                    <h3>Shipping Rates</h3>
                                    <p>All LTL freight shipping rates quoted on the livefreightquote.com platform are provided according to the Description of Goods (commodity, dimensions, and weight) and the NMFC Class (for the USA and USA cross-border shipments only) declared by the Shipper and may be subject to additional charges in the case of misdeclaration or misclassification.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="info-content-wrapper">
                                <div className="info-content-count">
                                    <h3>2</h3>
                                </div>
                                <div className="info-contents">
                                    <h3>Special Handling</h3>
                                    <p>If your load needs Special Handling, please select the special handling option for an accurate quote. Carriers will charge for Value-Added Services if they need to perform additional services not included in the quote.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="info-content-wrapper">
                                <div className="info-content-count">
                                    <h3>3</h3>
                                </div>
                                <div className="info-contents">
                                    <h3>Pickup Date and Transit Times</h3>
                                    <p>The pickup date and transit times may vary by carrier and are not guaranteed unless otherwise stated. Transit time starts with the first business day after pickup. The estimated transit time does not include the day of pickup, weekends, or holidays.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="info-content-wrapper">
                                <div className="info-content-count">
                                    <h3>4</h3>
                                </div>
                                <div className="info-contents">
                                    <h3>Truck Waiting Time</h3>
                                    <p>Be mindful of the truck waiting time. Rates quoted include between 15 and 30 minutes of loading and offloading time for LTL shipments, depending on the carrier. There may be additional charges for delays in loading, waiting at facilities for loading or offloading, and/or other unforeseen delays.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- transit time --> */}
            <div className="transit-time-container">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Sample Transit Times on Popular LiveFreightQuote.com Lanes</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="transittime-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Origin Region</th>
                                            <th>Destination Region</th>
                                            <th>Transit Time*</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>New York / Jersey</td>
                                            <td>Vancouver, BC</td>
                                            <td>4 - 7 days</td>
                                        </tr>
                                        <tr>
                                            <td>Seattle, WA</td>
                                            <td>Montreal, QC</td>
                                            <td>4 - 7 days</td>
                                        </tr>
                                        <tr>
                                            <td>Los Angeles, CA</td>
                                            <td>Toronto, ON</td>
                                            <td>3 - 5 days</td>
                                        </tr>
                                        <tr>
                                            <td>Toronto, ON</td>
                                            <td>Edmonton, AB</td>
                                            <td>3 - 5 days</td>
                                        </tr>
                                        <tr>
                                            <td>Dallas, TX</td>
                                            <td>Calgary, AB</td>
                                            <td>3 - 7 days</td>
                                        </tr>
                                        <tr>
                                            <td>Monteral, QC</td>
                                            <td>Vancouver, BC</td>
                                            <td>4 - 7 days</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="transittime-table-info">
                                <p>*Transit time are calendar days and apply to major cities in and around the origin/destination cities listed. They are provided as a range (min - max) and will vary depending on the day of week pickup. Add an extra day for locations beyond the regions major cities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="footer-wrapper">
                                <p>© 2024 LiveFreightQuote.com. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* modal */}
            <Modal show={show} onHide={handleClose} className="emailInfoModal">
                <Modal.Header closeButton>
                    <Modal.Title>Rate Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="emailInfoModal-content-wrapper">
                        <p>Your rate request has been received. It is outside of our standard pallet dimensions for auto-rating. Our pricing team is working on your customized rate and you will be emailed shortly from <Link to={'mailto:customerservice@mpsfreight.ca'}>customerservice@mpsfreight.ca</Link>. Please add this email address to your trusted sender list.</p>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showBook} onHide={handleBookClose} className="emailInfoModal">
                <Modal.Header closeButton>
                    <Modal.Title>Book Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="emailInfoModal-content-wrapper">
                        <p>Quote book request email sent.</p>
                    </div>
                </Modal.Body>
            </Modal>

            {/* start again confirmation */}
            <Modal show={startAgainModal} onHide={startAgainClose} className="startAgainModal">
                <Modal.Body>
                    <div className="startAgainModal-content-wrapper">
                        <h3>Reset Confirmation</h3>
                        <p>Are you sure you want to reset the quote?</p>
                        <div className="startAgainModal-btns">
                            <Button variant="" onClick={startAgainClose} className="theme-btn btn-border">No</Button>
                            <Button variant="" onClick={resetAll} className="theme-btn btn-main">Yes</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FreightBook;