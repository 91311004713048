import React, { useEffect } from 'react';

const WidgetComponent = () => {
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.setAttribute('data-use-service-core', '');
        script.defer = true;

        // Append the script to the document body
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="elfsight-app-df9e7378-2326-4b71-8682-431e66d1f031" data-elfsight-app-lazy></div>
    );
};

export default WidgetComponent;