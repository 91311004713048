import React from "react";
import { Link } from 'react-router-dom';
import Header from "../components/header";
import Footer from "../components/footer";
import FeatureIcon1 from '../images/featureIcon1.png';
import ImageSample from '../images/page-banner.jpg';
import Truck from '../images/truck.jpg';
import instantfreight from '../images/instantfreight.png';
import crossborder from '../images/cross-border.png';
import booking from '../images/booking.png';
import freightrates from '../images/freight-rates.png';
import advancedrates from '../images/advanced-rates.png';
import customersupport from '../images/customer-support.png';
import Tracking from '../images/featureIcon5.png';
import savemoney from '../images/savemoney.png';

const WhyChooseUs = () => {
    return (
        <div> 
            <Header />
            <div className="page-banner-container">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-10 col-lg-8 h-100">
                            <div className="page-banner-wrapper">
                                <h2 className="pagebanner-heading">Why Choose Us?</h2>
                                <h1 className="pagebanner-content-heading">Instant LTL Freight Quotes for Canada and USA Shipping</h1>
                                <p className="pagebanner-description">Get Real-Time LTL Freight Quotes for Canada and USA Shipping</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div className="section weprovide">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9">
                            <div className="banner-bottom-wrapper">
                                <p>When it comes to finding the best LTL freight quotes for shipping within Canada, from Canada to the USA, or from the USA to Canada, you need a partner you can trust. At LiveFreightQuote.com, we are dedicated to simplifying the freight shipping process by providing instant, accurate, and competitive LTL shipping rates from top carriers. Discover why businesses choose us as their go-to platform for all their freight shipping needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section whyuse">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Why Choose LiveFreightQuote.com for Your LTL Freight Shipping Needs?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={instantfreight} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Instant, Real-Time LTL Freight Quotes</h3>
                                <p className="icon-description">Time is money in the shipping business. That’s why we offer instant LTL freight quotes online. Our advanced freight rate system integrates with leading LTL carriers to provide you with real-time quotes in seconds. No more waiting for callbacks or dealing with outdated rates—just fast, reliable pricing at your fingertips.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={crossborder} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Comprehensive Cross-Border and Domestic Shipping Solutions</h3>
                                <p className="icon-description">Whether you are looking for cross-border shipping from Canada to the USA, shipping from the USA to Canada, or domestic LTL shipping across Canada, we’ve got you covered. We have partnered with a wide network of trusted carriers to ensure you get the best options for your shipping route and needs.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={booking} alt="Img" />
                                </div>
                                <h3 className="icon-heading">User-Friendly Platform for Easy Freight Booking</h3>
                                <p className="icon-description">Our platform is designed with ease of use in mind. We simplify the entire freight booking process—from obtaining quotes and comparing rates to booking and managing your shipments. With just a few clicks, you can select the best LTL shipping rate and carrier, confirm your shipment, and handle all documentation directly through our online portal.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={freightrates} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Transparent Pricing with No Hidden Fees</h3>
                                <p className="icon-description">At LiveFreightQuote.com, we believe in transparency. All of our LTL freight quotes are all-inclusive, meaning there are no hidden fees or surprises. You get a clear breakdown of costs upfront, so you can budget effectively and avoid unexpected charges.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={advancedrates} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Advanced API Integration with Leading LTL Carriers</h3>
                                <p className="icon-description">Our cutting-edge software directly integrates with the APIs of top LTL freight carriers, giving you access to the most accurate and up-to-date LTL shipping rates available. This advanced integration ensures that you’re always getting the best price and service options from reputable carriers.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={customersupport} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Reliable Customer Support from Freight Experts</h3>
                                <p className="icon-description">Shipping freight can be complicated, but our experienced customer support team is here to help you every step of the way. Whether you need assistance with choosing the right carrier, understanding freight classes, or tracking your shipment, our team is ready to provide personalized support.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={Tracking} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Real-Time Tracking and Shipment Management</h3>
                                <p className="icon-description">Stay in control of your shipments with our real-time tracking and management features. Our online portal allows you to monitor the status of your freight shipments, view shipping documents, and manage all aspects of your logistics in one place.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={savemoney} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Save Time and Money with Competitive LTL Freight Rates</h3>
                                <p className="icon-description">By offering the ability to compare LTL freight rates from multiple carriers, we empower you to find the most cost-effective option for your shipping needs. Our platform helps businesses save time and money by eliminating the need to visit multiple carrier websites or wait for quotes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section how-works">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-6 col-lg-7 h-100">
                            <div className="leftfull-content-wrapper">
                                <h3 className="section-heading text-white text-start">Coverage Across Canada and Cross-Border to the USA</h3>

                                <div className="leftfull-lists">
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">1</span>
                                        <div className="leftfull-item-content">
                                            <h3>Canada-Wide LTL Freight Shipping</h3>
                                            <p>From British Columbia to Newfoundland, we provide comprehensive coverage for all your domestic LTL shipping needs.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">2</span>
                                        <div className="leftfull-item-content">
                                            <h3>Cross-Border Freight Shipping from Canada to the USA</h3>
                                            <p>Get competitive rates and seamless service for shipping freight from Canada to the USA.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">3</span>
                                        <div className="leftfull-item-content">
                                            <h3>Cross-Border Freight Shipping from the USA to Canada</h3>
                                            <p>Enjoy reliable shipping solutions and the best rates for moving freight from the USA to Canada.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 h-100">
                            <div className="rightfull-image-wrapper">
                                <img className="img-fluid" src={Truck} alt="Img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section coverage">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Flexible Options for All Types of Freight Shipments</h2>
                                <p className="text-center">No matter your shipment size or type, we offer flexible solutions that cater to your specific needs. Whether you’re shipping a single pallet or multiple skids, our platform can accommodate your freight requirements with ease.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section bottom-info">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9">
                            <div className="bottom-info-wrapper">
                                <h3 className="section-heading">Choose LiveFreightQuote.com and Start Shipping Smarter Today!</h3>
                                <p>With LiveFreightQuote.com, you get more than just a freight quote—you get a complete LTL shipping solution. Our platform combines cutting-edge technology, a vast carrier network, and expert support to help you simplify your shipping process and save on costs. Join the growing number of businesses that trust us for their LTL freight shipping needs and experience the difference today.</p>
                                <Link to={'../'} className="theme-btn btn-main">Get an Instant LTL Freight Quote Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default WhyChooseUs;