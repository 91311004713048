import React from 'react'
import { GoogleMap, MarkerF } from '@react-google-maps/api';

const MapComponent = ({ lat, lng, location, visible }) => {
  const mapCenter = { lat, lng };


  const mapContainerStyle = {
    width: '100%',
    height: '160px',
  };
  const redIcon = {
    url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
    scaledSize: { width: 32, height: 32 },
  };

  return (
    visible && (
      <GoogleMap
        // key={uniqueKey}
        center={mapCenter}
        zoom={12}
        mapContainerStyle={mapContainerStyle}
        mapTypeId='roadmap'
      >
        <MarkerF position={mapCenter} icon={redIcon} />
      </GoogleMap>
    )
  );
};

export default React.memo(MapComponent);
