import React from "react";
import { Link } from 'react-router-dom';
import Header from "../components/header";
import Footer from "../components/footer";
import FeatureIcon1 from '../images/featureIcon1.png';
import ImageSample from '../images/page-banner.jpg';
import Truck from '../images/truck.jpg';
import enterdetails from '../images/enter-details.png';
import compare from '../images/compare.png';
import booking from '../images/booking.png';
import tracking from '../images/featureIcon5.png';
import delivery from '../images/delivery.png';

const HowItWorks = () => {
    return (
        <div> 
            <Header />
            <div className="page-banner-container">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-10 col-lg-8 h-100">
                            <div className="page-banner-wrapper">
                                <h2 className="pagebanner-heading">How It Works</h2>
                                <h1 className="pagebanner-content-heading">Instant LTL Freight Quotes for Canada and USA Shipping</h1>
                                <p className="pagebanner-description">Get Real-Time LTL Freight Quotes for Canada and USA Shipping</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div className="section weprovide">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9">
                            <div className="banner-bottom-wrapper">
                                <p>Navigating the complexities of LTL freight shipping can be challenging, but LiveFreightQuote.com makes it simple and straightforward. Our platform provides instant, accurate, and competitive LTL freight quotes for shipping within Canada, from Canada to the USA, and from the USA to Canada. Here’s a step-by-step guide on how our service works and how you can start shipping smarter today.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section whyuse">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Get Instant LTL Freight Quotes Online with LiveFreightQuote.com</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={enterdetails} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Enter Your Shipment Details</h3>
                                    <p>To get started with LiveFreightQuote.com, you need to provide the basic details of your shipment. This includes:</p>
                                    <ul>
                                        <li><b>Origin and Destination:</b> Enter the pickup and delivery locations for your freight shipment.</li>
                                        <li><b>Freight Dimensions and Weight:</b> Specify the dimensions, weight, and quantity of your shipment to get the most accurate quote.</li>
                                        <li><b>Freight Class (if applicable):</b> Indicate the freight class if known; if not, our platform can help determine it for you based on the provided details.</li>
                                    </ul>
                                    <p className="mt-3">By entering these details, our system will search for the best LTL freight rates from our network of trusted carriers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={compare} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Compare Instant LTL Freight Quotes</h3>
                                    <p>Once you’ve entered your shipment information, click "Get Quote" to instantly receive LTL freight quotes from multiple carriers. Our platform is integrated with leading LTL carriers across Canada and the USA, allowing you to:</p>
                                    <ul>
                                        <li><b>Compare LTL Shipping Rates:</b> View and compare competitive rates from top carriers.</li>
                                        <li><b>Review Carrier Options:</b> Compare carriers based on cost, transit time, and service level.</li>
                                        <li><b>Select the Best Rate:</b> Choose the rate and carrier that best suits your shipping needs and budget.</li>
                                    </ul>
                                    <p className="mt-3">Our system provides real-time freight quotes that reflect the most current prices available, ensuring you always get the best deal.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={booking} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Book Your Freight Shipment Online</h3>
                                    <p>After selecting the best LTL freight quote, you can proceed to book your shipment directly through our platform. The booking process is quick and straightforward:</p>
                                    <ul>
                                        <li><b>Confirm Shipment Details:</b> Review all shipment details to ensure accuracy.</li>
                                        <li><b>Choose Additional Services (if needed):</b> Select any additional services such as liftgate, residential delivery, or inside pickup.</li>
                                        <li><b>Secure Your Booking:</b> Confirm and pay for your shipment securely through our online portal.</li>
                                    </ul>
                                    <p className="mt-3">Our user-friendly interface makes the booking process seamless, saving you time and eliminating the hassle of coordinating with multiple carriers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={tracking} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Access Real-Time Shipment Tracking</h3>
                                    <p>Once your shipment is booked, you can track it in real time through our platform. Stay updated with:</p>
                                    <ul>
                                        <li><b>Real-Time Tracking Updates:</b> Monitor your shipment's progress from pickup to delivery.</li>
                                        <li><b>Manage Shipping Documents:</b> Easily access and download all necessary shipping documents, including the Bill of Lading (BOL) and delivery receipts.</li>
                                        <li><b>Receive Notifications:</b> Get email notifications for important updates, such as pickup confirmation and delivery status.</li>
                                    </ul>
                                    <p className="mt-3">Our platform offers complete visibility and control over your LTL freight shipments, allowing you to manage your logistics with confidence.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={delivery} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Delivery and Confirmation</h3>
                                    <p>Your chosen carrier will handle the pickup, transport, and delivery of your freight. Once the shipment is delivered:</p>
                                    <ul>
                                        <li><b>Receive Delivery Confirmation:</b> Get notified as soon as your shipment reaches its destination.</li>
                                        <li><b>Review Shipment Performance:</b> Provide feedback on the carrier's performance to help us maintain high-quality service standards.</li>
                                    </ul>
                                    <p className="mt-3">With LiveFreightQuote.com, you can rest assured that your LTL freight shipments are in good hands, from booking to final delivery.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section how-works">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-6 col-lg-7 h-100">
                            <div className="leftfull-content-wrapper">
                                <h3 className="section-heading text-white text-start">Why Use LiveFreightQuote.com for LTL Freight Shipping?</h3>

                                <div className="leftfull-lists">
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">1</span>
                                        <div className="leftfull-item-content">
                                            <h3>Instant and Accurate LTL Freight Quotes</h3>
                                            <p>Get real-time quotes from top carriers.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">2</span>
                                        <div className="leftfull-item-content">
                                            <h3>Wide Network of Trusted Carriers</h3>
                                            <p>Access competitive rates from leading carriers in Canada and the USA.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">3</span>
                                        <div className="leftfull-item-content">
                                            <h3>Simple and Transparent Process</h3>
                                            <p>No hidden fees or complicated procedures—just straightforward, all-inclusive pricing.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">4</span>
                                        <div className="leftfull-item-content">
                                            <h3>User-Friendly Online Platform</h3>
                                            <p>Easily compare rates, book shipments, and track deliveries in one place.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">5</span>
                                        <div className="leftfull-item-content">
                                            <h3>Expert Customer Support</h3>
                                            <p>Our team is always available to assist with any questions or concerns.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 h-100">
                            <div className="rightfull-image-wrapper">
                                <img className="img-fluid" src={Truck} alt="Img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section bottom-info">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9">
                            <div className="bottom-info-wrapper">
                                <h3 className="section-heading">Start Shipping Smarter with LiveFreightQuote.com!</h3>
                                <p>LiveFreightQuote.com makes LTL freight shipping easy, efficient, and affordable. By streamlining the process of obtaining LTL shipping rates, booking shipments, and managing logistics, we empower businesses to save time and reduce shipping costs.</p>
                                <Link to={'../'} className="theme-btn btn-main">Get Your Instant LTL Freight Quote Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <Footer />
        </div>
    );
};

export default HowItWorks;