import React, { useState } from "react";
import { Link } from 'react-router-dom';
import csa from '../images/csa.png';
import { useLocation } from 'react-router-dom/dist';
import moment from 'moment';
import Logo from '../images/main-logo.png';

const FreightQuote = () => {
    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
        setIsActive(!isActive); // Toggle the state
    };
    function formatCustomDate(inputDate) {
        const dateObj = moment(inputDate);
        const dayOfMonth = dateObj.date();
        const monthName = dateObj.format("MMMM");
        const year = dateObj.year();

        let daySuffix;
        if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
            daySuffix = "st";
        } else if (dayOfMonth === 2 || dayOfMonth === 22) {
            daySuffix = "nd";
        } else if (dayOfMonth === 3 || dayOfMonth === 23) {
            daySuffix = "rd";
        } else {
            daySuffix = "th";
        }

        return `${dateObj.format("ddd")} ${dateObj.format("DD").replace(/^0/, "")}${daySuffix} ${monthName} ${year}`;
    }

    const location = useLocation();
    const quoteResponse = location.state && location.state.quoteResponse;

    return (
        <div className="main-container">
            {/* <!-- header --> */}
            <div className="page-header-container">
                <div className="top-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="topheader-wrapper">
                                <Link to={'../'}><img className="pageBrand-img" src={Logo} alt="Img" /></Link>
                                    <div className="topheader-contact">
                                        <a href="tel:866-200-9989"><i className="fa fa-phone"></i>866-200-9989</a>
                                        <a href="mailto:livefreightquote@gmail.com"><i className="fa fa-envelope"></i>livefreightquote@gmail.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-header"></div>
            </div>
            {/* <!-- forms --> */}
            <div className="main-form-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="form-content-wrapper">
                                {/* <!-- info banner --> */}
                                <div className="form-info-banner-wrapper">
                                    <div className="form-info-banner">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-12 col-md-11 col-lg-11">
                                                <div className="backtoBook">
                                                    <Link to={'../book/details'}><i className="fa fa-long-arrow-left"></i>Back to Book</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h1 className="info-banner-heading">Quotes for your shipment</h1>
                                                <p className="info-banner-small-description">Please review the quotes.</p>
                                                <p className="info-banner-small-description mb-3">Keep in mind that if the
                                                    shipment differs in any way from the details entered, these quotes may
                                                    not be valid.</p>
                                                <p className="info-banner-small-description">⚠️ For some lanes, the best rates
                                                    are only available if pickup is within 3-4 days from today.</p>
                                            </div>
                                        </div>
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-12 col-md-11 col-lg-11">
                                                <div className="quotes-heading">
                                                    <h3>Quotes</h3>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-11 col-lg-11">
                                                {quoteResponse && (
                                                    <div className="quote-list">
                                                        {/* <!-- quote item 1 --> */}
                                                        {quoteResponse.quotes.orders.map((quote, index) => (
                                                            <div className="quote-item" key={index}>
                                                                <div className="quote-main-content">
                                                                    <div className="quote-item-logo">
                                                                        <img className="img-fluid" src={csa}
                                                                            alt="Img" />
                                                                    </div>
                                                                    <div className={`quote-item-content ${isActive ? 'hide' : ''}`}>
                                                                        <div className="quote-item-service-level">
                                                                            <p className="quote-item-heading">Service Level</p>
                                                                            <h2 className="quote-item-result">{''}</h2>
                                                                        </div>
                                                                        <div className="quote-item-delivery">
                                                                            <p className="quote-item-heading">Earliest Delivery</p>
                                                                            <h2 className="quote-item-result">{formatCustomDate(quote.estimatedDeliveryDate)}</h2>
                                                                        </div>
                                                                        <div className="quote-item-transit">
                                                                            <p className="quote-item-heading">Transit</p>
                                                                            <h2 className="quote-item-result">
                                                                                {quote.details[0].userFieldInt2}
                                                                                <span className="plus">+</span>
                                                                                <span className="days">Business<br />Days</span>
                                                                            </h2>
                                                                        </div>
                                                                        <div className="quote-item-price">
                                                                            <h1><span className="price-dollar">$</span>{Number(quote.totalCharges) * 1.22}<span
                                                                                className="price-currency">{quote.currencyCode}</span></h1>
                                                                        </div>
                                                                    </div>
                                                                    <div className="quote-drop-wrapper">
                                                                        {/* <!-- collapse --> */}
                                                                        <button onClick={handleClick} className="quoteDownbtn" type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#collapseExample" aria-expanded="false"
                                                                            aria-controls="collapseExample"></button>
                                                                    </div>
                                                                </div>
                                                                <div className="quote-item-dropcontent">
                                                                    <div className="collapse" id="collapseExample">
                                                                        <div className="quote-collapse-content">
                                                                            <div className="qcc-head">
                                                                                <div className="qcc-head-left">
                                                                                    <h2>Transit: {quote.details[0].userFieldInt2}+ Business Days</h2>
                                                                                    <p>Earliest Delivery: {moment(quote.estimatedDeliveryDate).format("ddd DD  MMMM YYYY")}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="qcc-head-right">
                                                                                    <h2><span className="qcc-dollar">$</span>{Number(quote.totalCharges) * 1.22}<span
                                                                                        className="qcc-currency">{quote.currencyCode}</span></h2>
                                                                                    <p>Inclusive of all taxes</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="qcc-body">
                                                                                <div className="qcc-body-list pickup">
                                                                                    <div className="qcc-body-list-heading">
                                                                                        <h3>Pickup</h3>
                                                                                    </div>
                                                                                    <div className="qcc-details">
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                When</h4>
                                                                                            <h3 className="qcc-detail-result-heading">
                                                                                                {formatCustomDate(quote.pickUpBy)}</h3>
                                                                                            <p>Pickup window: {moment(quote.pickUpBy).format("ha")}-{moment(quote.pickUpByEnd).format("ha")}</p>
                                                                                        </div>
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Where</h4>
                                                                                            <h3 className="qcc-detail-result-heading">
                                                                                                {quote.startZoneDescription}</h3>
                                                                                            {/* <p>Warehouse</p> */}
                                                                                        </div>
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Payload</h4>
                                                                                            <h3 className="qcc-detail-result-heading">
                                                                                                Total Weight: {quote.weight}lb</h3>
                                                                                            {quote.details.map((detail, index) => (
                                                                                                <div>
                                                                                                    <p>{detail.pieces} X {detail.piecesUnits} {detail.description}</p>
                                                                                                    <p>{detail.length}" X {detail.width}" x {detail.height}"</p>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                        {/* <div className="qcc-detail-item">
                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                        Special Services</h4>
                                                                                    <ul>
                                                                                        <li>None Selected</li>
                                                                                    </ul>
                                                                                </div> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="qcc-body-list transit">
                                                                                    <div className="qcc-body-list-heading">
                                                                                        <h3>Transit</h3>
                                                                                    </div>
                                                                                    <div className="qcc-details">
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Carrier</h4>
                                                                                            <p>CSA</p>
                                                                                        </div>
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Service Type</h4>
                                                                                            <p>{''}</p>
                                                                                        </div>
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Transit Time</h4>
                                                                                            <p>{quote.details[0].userFieldInt2}+ business days</p>
                                                                                        </div>
                                                                                        {/* <div className="qcc-detail-item">
                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                        Maximum Liability</h4>
                                                                                    <p>$2 / pound</p>
                                                                                </div> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="qcc-body-list transit">
                                                                                    <div className="qcc-body-list-heading">
                                                                                        <h3>Delivery</h3>
                                                                                    </div>
                                                                                    <div className="qcc-details">
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Earliest Delivery</h4>
                                                                                            <h3 className="qcc-detail-result-heading">{formatCustomDate(quote.deliverBy)}</h3>
                                                                                        </div>
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Last Delivery</h4>
                                                                                            <h3 className="qcc-detail-result-heading">{formatCustomDate(quote.deliverByEnd)}</h3>
                                                                                        </div>
                                                                                        <div className="qcc-detail-item">
                                                                                            <h4 className="qcc-detail-small-heading">
                                                                                                Where</h4>
                                                                                            <h3 className="qcc-detail-result-heading">{quote.endZoneDescription}</h3>
                                                                                            {/* <p>Warehouse</p> */}
                                                                                        </div>
                                                                                        {/* <div className="qcc-detail-item">
                                                                                    <h4 className="qcc-detail-small-heading">
                                                                                        Special Services</h4>
                                                                                    <ul>
                                                                                        <li>None Selected</li>
                                                                                    </ul>
                                                                                </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="footer-wrapper">
                                <p>© 2024 LiveFreightQuote.com. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default FreightQuote;