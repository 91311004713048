import React from "react";
import { Link } from 'react-router-dom';
import Header from "../components/header";
import Footer from "../components/footer";
import FeatureIcon1 from '../images/featureIcon1.png';
import Truck from '../images/truck.jpg';
import instantfreight from '../images/instantfreight.png';
import crossborder from '../images/cross-border.png';
import booking from '../images/booking.png';
import freightrates from '../images/freight-rates.png';
import advancedrates from '../images/advanced-rates.png';
import customersupport from '../images/customer-support.png';
import Arrows from '../images/arrows.png';
import FreightBook from "./freightbook";

const Services = () => {
    return (
        <div>
            <Header />
            <div className="page-banner-container">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-10 col-lg-8 h-100">
                            <div className="page-banner-wrapper">
                                <h2 className="pagebanner-heading">Services</h2>
                                <h1 className="pagebanner-content-heading">Instant LTL Freight Quotes for Canada and USA Shipping</h1>
                                <p className="pagebanner-description">Get Real-Time LTL Freight Quotes for Canada and USA Shipping</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section weprovide">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9">
                            <div className="banner-bottom-wrapper">
                                <p>Looking for instant LTL (Less-Than-Truckload) freight quotes? AtLiveFreightQuote.com, we providereal-time LTL freight rates for shipping from Canada to the USA, USA to Canada, and within Canada. Our advanced freight quote system allows businesses to compare shipping rates, select the best carrier, and book shipments instantly—all on one convenient platform.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section whyuse">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Why Use LiveFreightQuote.com for LTL Freight Shipping?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={instantfreight} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Instant, Accurate LTL Freight Quotes Online</h3>
                                <p className="icon-description">Our powerful platform delivers instant LTL freight quotes from multiple carriers, giving you up-to-date pricing in seconds. No more waiting for phone calls or emails—simply enter your shipment details and get real-time freight quotes that you can rely on.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={crossborder} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Seamless Cross-Border and Domestic Shipping</h3>
                                <p className="icon-description">We specialize in both cross-border shipping between Canada and the USA and domestic Canadian LTL freight shipping. Whether you need to ship from Canada to the USA or within Canada, our extensive carrier network ensures that you get competitive LTL freight rates tailored to your shipping needs.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={booking} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Easy Online Freight Booking</h3>
                                <p className="icon-description">Our user-friendly platform allows you to compare LTL shipping rates and book your freight shipment with just a few clicks. Our system handles everything from freight class calculation to documentation, providing a seamless shipping experience from start to finish.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={freightrates} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Transparent LTL Freight Rates with No Hidden Fees</h3>
                                <p className="icon-description">We provide 100% transparent pricing with no hidden fees. All LTL freight quotes on LiveFreightQuote.com are all-inclusive, so what you see is what you pay. This helps businesses budget accurately for their shipping needs without any surprises.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={advancedrates} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Advanced Freight Rate Technology</h3>
                                <p className="icon-description">Our software integrates directly with top LTL freight carriers' APIs to fetch the latest LTL shipping rates. This means you get the most competitive prices and service options in the market without the hassle of visiting multiple carrier websites.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="icon-card-wrapper">
                                <div className="icon-image">
                                    <img className="img-fluid" src={customersupport} alt="Img" />
                                </div>
                                <h3 className="icon-heading">Reliable Customer Support for Freight Shipping</h3>
                                <p className="icon-description">Our team of freight shipping experts is always ready to assist you. From understanding LTL freight classes to tracking your shipment, we provide dedicated support to ensure a smooth shipping experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section how-works">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-6 col-lg-7 h-100">
                            <div className="leftfull-content-wrapper">
                                <h3 className="section-heading text-white text-start">How LiveFreightQuote.com Works</h3>

                                <div className="leftfull-lists">
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">1</span>
                                        <div className="leftfull-item-content">
                                            <h3>Enter Your Freight Details</h3>
                                            <p>Provide the origin, destination, weight, and dimensions of your shipment to get an accurate quote.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">2</span>
                                        <div className="leftfull-item-content">
                                            <h3>Compare LTL Shipping Rates</h3>
                                            <p>Instantly compare quotes from top LTL carriers in Canada and the USA.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">3</span>
                                        <div className="leftfull-item-content">
                                            <h3>Book Your Freight Shipment Online</h3>
                                            <p>Choose the best LTL freight rate and carrier for your needs, and book your shipment directly through our secure platform.</p>
                                        </div>
                                    </div>
                                    <div className="leftfull-lists-item">
                                        <span className="leftfull-count">4</span>
                                        <div className="leftfull-item-content">
                                            <h3>Track and Manage Your Shipment</h3>
                                            <p>Use our online portal to track your LTL freight shipment in real time and manage all shipping documents.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 h-100">
                            <div className="rightfull-image-wrapper">
                                <img className="img-fluid" src={Truck} alt="Img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section coverage">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Coverage Areas for LTL Freight Shipping</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="bg-card-wrapper">
                                <img className="img-fluid" src={instantfreight} alt="Img" />
                                <h3>Canada-Wide LTL Freight Shipping</h3>
                                <p>Get competitive LTL freight rates for shipping across Canada, from British Columbia to Newfoundland.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="bg-card-wrapper">
                                <img className="img-fluid" src={Arrows} alt="Img" />
                                <h3>LTL Freight Shipping from Canada to USA</h3>
                                <p>Simplify cross-border shipping with our seamless LTL freight solutions.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="bg-card-wrapper">
                                <img className="img-fluid" src={Arrows} alt="Img" />
                                <h3>LTL Freight Shipping from USA to Canada</h3>
                                <p>Get the best rates and services for shipping from the USA to Canada with trusted carriers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section benefits">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Benefits of Using LiveFreightQuote.com</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 mb-4">
                            <div className="point-card-wrapper">
                                <div className="points-item">
                                    <p>Instant Freight Quotes Online</p>
                                </div>
                                <div className="points-item">
                                    <p>Competitive Rates from Top LTL Carriers</p>
                                </div>
                                <div className="points-item">
                                    <p>Easy Online Freight Booking and Management</p>
                                </div>
                                <div className="points-item">
                                    <p>Real-Time Shipment Tracking</p>
                                </div>
                                <div className="points-item">
                                    <p>No Hidden Fees or Charges</p>
                                </div>
                                <div className="points-item">
                                    <p>Reliable Customer Support</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section bottom-info">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9">
                            <div className="bottom-info-wrapper">
                                <h3 className="section-heading">Start Saving on LTL Freight Shipping Today!</h3>
                                <p>Ready to simplify your freight shipping process and save on costs? Visit LiveFreightQuote.com to get your instant LTL freight quote now. Experience the convenience of comparing LTL shipping rates and booking freight shipments online. Start shipping smarter with LiveFreightQuote.com!</p>
                                <Link to={'../'} className="theme-btn btn-main">Get an Instant LTL Freight Quote Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Services;