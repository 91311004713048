import React from "react";
import { Link } from 'react-router-dom';
import Header from "../components/header";
import Footer from "../components/footer";
import FeatureIcon1 from '../images/featureIcon1.png';
import ImageSample from '../images/page-banner.jpg';
import coverage from '../images/coverage.png';
import management from '../images/management.png';
import advancedrates from '../images/advanced-rates.png';
import flexible from '../images/flexible.png';
import instantfreight from '../images/instantfreight.png';
import crossborder from '../images/cross-border.png';
import booking from '../images/booking.png';
import freightrates from '../images/freight-rates.png';
import customersupport from '../images/customer-support.png';
import trust from '../images/trust.png';

const UniqueOffering = () => {
    return (
        <div> 
            <Header />
            <div className="page-banner-container">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-10 col-lg-8 h-100">
                            <div className="page-banner-wrapper">
                                <h2 className="pagebanner-heading">Our Unique Offering</h2>
                                <h1 className="pagebanner-content-heading">Instant LTL Freight Quotes for Canada and USA Shipping</h1>
                                <p className="pagebanner-description">Get Real-Time LTL Freight Quotes for Canada and USA Shipping</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div className="section weprovide">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9">
                            <div className="banner-bottom-wrapper">
                                <p>When it comes to choosing a reliable partner for LTL freight shipping, LiveFreightQuote.com offers a unique set of features and benefits that set us apart from the competition. Our platform is designed to provide the best LTL freight quotes, exceptional service, and an unbeatable user experience. Here’s what makes LiveFreightQuote.com the preferred choice for businesses looking to ship freight across Canada and between Canada and the USA.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section whyuse">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="section-heading-wrapper">
                                <h2 className="section-heading">Why LiveFreightQuote.com Stands Out in LTL Freight Shipping?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={coverage} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Comprehensive Coverage for Canada and USA Shipping</h3>
                                    <p>We specialize in providing LTL freight shipping solutions for both domestic shipments within Canada and cross-border shipments between Canada and the USA. Our extensive network of trusted carriers allows us to offer competitive rates and services for:</p>
                                    <ul>
                                        <li><b>Canada-Wide LTL Shipping:</b> From coast to coast, we provide reliable shipping options for businesses across Canada.</li>
                                        <li><b>Cross-Border Shipping from Canada to USA:</b> Simplify your cross-border logistics with seamless service and competitive pricing.</li>
                                        <li><b>Cross-Border Shipping from USA to Canada:</b> Get the best rates and services for shipping from the USA to Canada with top carriers.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={management} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>User-Friendly Platform for Easy Freight Management</h3>
                                    <p>Our platform is designed with the user in mind, offering a simple, intuitive interface that makes it easy to:</p>
                                    <ul>
                                        <li><b>Compare LTL Freight Rates:</b> Instantly view and compare rates from multiple carriers.</li>
                                        <li><b>Book Freight Shipments Online:</b> Securely book your shipment with just a few clicks.</li>
                                        <li><b>Manage Shipping Documents:</b> Easily access, download, and manage all shipping documents in one place.</li>
                                    </ul>
                                    <p className="mt-3">This streamlined approach saves businesses time and reduces the complexity of managing LTL freight shipments.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={advancedrates} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Advanced Technology for Better Shipping Solutions</h3>
                                    <p>LiveFreightQuote.com leverages cutting-edge technology to enhance the shipping experience. Our platform’s direct integration with carriers through advanced API technology allows us to provide:</p>
                                    <ul>
                                        <li><b>Real-Time Rate Updates:</b> Always get the most current rates available from top carriers.</li>
                                        <li><b>Automated Freight Class Calculation:</b> Ensure accurate quotes and avoid reclassification fees.</li>
                                        <li><b>Real-Time Shipment Tracking:</b> Monitor your shipment's progress from pickup to delivery through our online portal.</li>
                                    </ul>
                                    <p className="mt-3">This advanced technological integration ensures that businesses can make informed decisions quickly and efficiently.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={flexible} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Flexible Shipping Options for All Types of Freight</h3>
                                    <p>No two shipments are the same, and we understand that. That’s why we offer a variety of LTL shipping options to accommodate different freight needs, including:</p>
                                    <ul>
                                        <li><b>Standard LTL Shipping:</b> Ideal for small to medium-sized shipments that don’t require a full truckload.</li>
                                        <li><b>Expedited Shipping Options:</b> For time-sensitive shipments that need to arrive quickly.</li>
                                        <li><b>Special Handling Services:</b> Choose additional services like liftgate, residential delivery, or inside pickup to meet specific delivery requirements.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={booking} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Streamlined Booking and Shipment Management Process</h3>
                                    <p>Our easy-to-use platform simplifies the entire freight booking process, from getting LTL shipping rates to booking and managing shipments. With our all-in-one portal, you can:</p>
                                    <ul>
                                        <li><b>Get Instant Quotes:</b> Quickly compare rates and choose the best option.</li>
                                        <li><b>Book Your Shipment:</b> Complete the booking process in minutes with our secure payment system.</li>
                                        <li><b>Track and Manage Shipments:</b> Access all shipping documents, track shipments in real time, and receive notifications for delivery updates.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={instantfreight} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Instant and Accurate LTL Freight Quotes</h3>
                                    <p>One of our core offerings is the ability to provide instant LTL freight quotes online. Our advanced platform integrates directly with the APIs of top LTL freight carriers to deliver real-time, accurate freight quotes in seconds. This means no more waiting for quotes over the phone or dealing with outdated rates—just fast and reliable pricing when you need it.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={freightrates} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Transparent Pricing with No Hidden Fees</h3>
                                    <p>We believe in transparency and honesty when it comes to pricing. That’s why all our LTL freight quotes are all-inclusive, with no hidden fees or unexpected charges. Our clear, upfront pricing helps businesses budget accurately for their shipping needs and avoid surprises.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={customersupport} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Dedicated Customer Support from Freight Experts</h3>
                                    <p>Our team of freight experts is always available to provide personalized support and guidance. Whether you need help choosing the right LTL freight carrier, understanding freight classes, or managing a complicated shipment, we are here to ensure a smooth and hassle-free experience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                            <div className="halfbg-wrapper">
                                <div className="halfbg-icon">
                                    <img className="img-fluid" src={trust} alt="Img" />
                                </div>
                                <div className="halfbg-content">
                                    <h3>Proven Track Record of Reliability and Trust</h3>
                                    <p>We pride ourselves on our reputation for reliability and trustworthiness. Businesses across Canada and the USA rely on LiveFreightQuote.com for their LTL freight shipping needs because we deliver on our promises of accuracy, transparency, and exceptional service.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section bottom-info">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9">
                            <div className="bottom-info-wrapper">
                                <h3 className="section-heading">Experience the LiveFreightQuote.com Difference Today!</h3>
                                <p>LiveFreightQuote.com is not just another freight shipping platform—we are a complete solution for businesses looking to optimize their logistics and save on shipping costs. With our unique offerings, competitive rates, and user-friendly platform, we provide a smarter way to manage LTL freight shipping.</p>
                                <Link to={'../'} className="theme-btn btn-main">Get Your Instant LTL Freight Quote Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default UniqueOffering;