import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LogoBlack from '../images/logo-black.png';

const Header = () => {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 50;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    return (
        <Navbar expand="lg" className={`bg-body-tertiary ${scrolled ? 'navbar-scrolled' : ''}`}>
            <Container>
                <Navbar.Brand>
                    <Link to={'../'}>
                        <img className="img-fluid" src={LogoBlack} alt="Img" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        navbarScroll
                    >   <Nav.Item>
                            <Link className="nav-link" to={'../services'}>Services</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className="nav-link" to={'../why-choose-us'}>Why choose us?</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className="nav-link" to={'../unique-offering'}>Unique offering</Link>
                        </Nav.Item>
                        {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
                            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action4">
                                Why choose us?
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action5">
                                Unique offering
                            </NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Item>
                            <Link className="nav-link" to={'../how-it-works'}>How it works</Link>
                        </Nav.Item>
                    </Nav>
                    <Form className="d-flex align-items-center headerbtns">
                        <a href="tel:647-424-0711" className="call-link"><i className="fa fa-phone"></i>647-424-0711</a>
                        {/* <Button variant="" className="theme-btn btn-main">Get a quote</Button> */}
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;